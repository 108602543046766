<!-- page title -->
<app-header></app-header>
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <ul class="list-inline custom-breadcrumb">
            <li class="list-inline-item"><a class="h2 text-primary font-secondary">Painel de Gestão</a></li>
            <li class="list-inline-item text-white h3 font-secondary @@nasted"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- /page title -->
  

  <!-- /notice -->
  <app-footer></app-footer>