<app-header></app-header>
<section class="mb-3">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <h2 *ngIf="isNew">Nova Notícia</h2>
          <h2 *ngIf="isEdit">Editar Notícia</h2>
          <hr>

          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <h3>Título da Notícia</h3>
                <input type="text" class="form-control" placeholder="" [(ngModel)]="newsObject.title"/>   
                <small class="form-text text-muted">Máx. XX car.</small>  
              </div>
              <div class="form-group">
                <h3>Link Imagem</h3>
                <input type="url" class="form-control" placeholder="https://..." [(ngModel)]="newsObject.image" />     
              </div>
            </div>
            <div class="col-xl-6" *ngIf="newsObject.image != ''">
              <h3>Preview Imagem</h3>
              <img src="{{newsObject.image}}" class="image-preview"/>
            </div>
          </div>  
          
          
          <div class="mb-3">
            <h3>Pequena Descrição</h3>
            <quill-editor placeholder="Corpo da Noticia" [styles]="{height: '100px'}" [(ngModel)]="newsObject.short_description"></quill-editor>
            <small class="form-text text-muted">Máx. XX car.</small>  
          </div>
          
          
          <div class="mb-3">
            <h3>Corpo da Notícia</h3>
            <quill-editor placeholder="Corpo da Noticia" [styles]="{height: '500px'}" [(ngModel)]="newsObject.body"></quill-editor>
          </div>
        </div>
      </div>
      <button class="btn btn-warning" (click)="goBack()">voltar</button>
      <button class="btn btn-success float-right" (click)="submit()"><span *ngIf="isNew">Criar</span><span *ngIf="isEdit" >Guardar</span></button>
      <div class="clearfix"></div>
    </div>
  </section>
  <app-footer></app-footer>