import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminguardService implements CanActivate {

  constructor(private session : SessionService, private router:Router) { }

  canActivate(next: ActivatedRouteSnapshot): boolean {
    
    let jwtData = this.session.getJWTData();
    if(jwtData.id){
      if(next.data.roles){      
        let jwtRoles = jwtData.role.split(";");
        let permissionFound = false;
        jwtRoles.forEach((element:any) => {
          if(next.data.roles.includes(element)){
            permissionFound = true;
          }
        });
          return permissionFound;
        }else{
          this.router.navigate(['/']);
          return false;
      }        
    }else{
   
      this.router.navigate(['/']);
      return false;
    }
  }
}
