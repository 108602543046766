import { Component, HostBinding } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    // animation triggers go here
  ]
})
export class AppComponent {
  title = 'Portal Associação Vale d\'Ouro';
  isAdmin : boolean = false;
  isSocio : boolean = false;

  constructor(private router : Router){

    this.router.events.subscribe((val) => {
      // see also 
      if(val instanceof NavigationEnd && val.urlAfterRedirects.includes('admin')){
        this.isAdmin = true;
      }else if(val instanceof NavigationEnd && !val.urlAfterRedirects.includes('admin')){
        this.isAdmin = false;
      }
  });

  }

}
