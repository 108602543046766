<!-- Modal -->
<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content rounded-0 border-0 p-4">
            <div class="modal-header border-0">
                <h3>Login</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form action="#" class="row">
                    <div class="col-12">
                        <input type="email" (keyup)="validateInput()" class="form-control mb-3" [(ngModel)]="loginName" id="loginName" name="loginName" placeholder="Email" required>
                    </div>
                    <div class="col-12">
                        <input type="password" (keyup)="validateInput()" class="form-control mb-3" [(ngModel)]="loginPassword" id="loginPassword" name="loginPassword" placeholder="Password" required>
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary submit" (click)="login($event)" [disabled]="submitDisabled">LOGIN</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>