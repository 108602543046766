<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="row mb-5">
        <!-- speakers -->
        <div class="col-lg-4">
          <div class="media">
            <img class="mr-3 img-fluid" src="https://avtrasosmontes.associacaovaledouro.pt/img/logo_completo.png"
              width="150px" alt="speaker">
            <div class="media-body">
              <h5 class="mt-0">Linha Alta Velocidade Porto - Madrid</h5>
              <a href="https://www.avtrasosmontes.associacaovaledouro.pt/" class="btn btn-primary btn-sm" data-animation-out="fadeOutRight" data-delay-out="5"
                data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7" target="_blank">Saber mais</a>
            </div>
          </div>
        </div>
        <!-- speakers -->
        <div class="col-lg-4">
          <div class="media">
            <img class="mr-3 img-fluid"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8CdmRPpr8BOfY9q-x3eUqZAFlQFlt3KkSlQ&usqp=CAU"
              width="150px" alt="speaker">
            <div class="media-body">
              <h4 class="mt-0">Mostra de Teatro do Douro</h4>
              <a href="https://mostrateatrodouro.associacaovaledouro.pt/" class="btn btn-primary btn-sm" data-animation-out="fadeOutRight" data-delay-out="5"
                data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7" target="_blank">Saber mais</a>
            </div>
          </div>
        </div>
        <!-- speakers -->
        <div class="col-lg-4">
          <div class="media">
            <img class="mr-3 img-fluid"
              src="https://cdn-images-1.listennotes.com/podcasts/pcm-associa%C3%A7%C3%A3o-vale-douro-QpdbO3c0JFB-kAV28PZNuym.789x789.jpg"
              width="100px" alt="speaker">
            <div class="media-body">
              <h4 class="mt-0">PCM</h4>
              <a href="https://www.youtube.com/@PCMASCVD" class="btn btn-primary btn-sm" data-animation-out="fadeOutRight" data-delay-out="5"
                data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7" target="_blank">Saber mais</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 order-2 order-md-1 mt-5">
        <h2 class="section-title">DESAFIOS</h2>
        <ol type="a" class="mb-4">
          <li><strong>CONSOLIDAR</strong> os grandes eventos de referência e que constituem a matriz de atuação da
            associação bem como os valores e princípios da instituição</li>
          <li><strong>DEFENDER</strong> os grandes anseios do território colocando à disposição da região os recursos
            disponíveis</li>
          <li><strong>ACRESCENTAR</strong> valor ao território com ações inovadores, sustentáveis e identitárias</li>
          <li><strong>REFORÇAR</strong> a presença na região alargando o número de parceiros, associados e as atividades
          </li>
        </ol>
        <a href="/documentos#estatutos" fragment="estatutos" class="btn btn-primary-outline mb-2">Estatutos</a>&nbsp;&nbsp;&nbsp;
        <a href="https://files.dre.pt/2s/2019/10/204000000/0002200022.pdf" target="_blank" class="btn btn-primary-outline mb-2">Declaração Utilidade Pública</a>
      </div>
      <div class="col-md-6 order-1 order-md-2 mb-4 mb-md-0">
        <img class="img-fluid w-100" src="../../assets/img/pub.jpg" alt="about image">
      </div>
    </div>
  </div>
</section>