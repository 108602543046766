  <h2>Sócios</h2>
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col">Nr Socio</th>
          <th scope="col">Nome</th>
          <th scope="col">Email</th>
          <th scope="col">Telemovel</th>
          <th scope="col">Ultima Quota Paga</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let socio of socios">
          <td><a routerLink="/admin/panel/socios/{{socio.nr_socio}}">{{socio.nr_socio}}</a></td>
          <td>{{socio.nome}}</td>
          <td>{{socio.email}}</td>
          <td>{{socio.telemovel}}</td>
          <td *ngIf="socio.quota[0]">{{socio.quota[0].data_fim | date:'MMM d, y'}}</td>
          <td *ngIf="!socio.quota[0]">Sem registo de quota</td>
        </tr>
      </tbody>
    </table>
  </div>