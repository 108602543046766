<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item">
            <a class="h2 text-primary font-secondary">Novo Sócio</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container mt-3 quotas" *ngIf="step == 1">
  <p>Escolha o tipo de Sócio</p>
  <p class="small">passo {{ step }} de {{ maxsteps }}</p>
  <ul class="list-group">
    <li class="list-group-item" (click)="selectSocioType($event, 'socio')" [class.activeQuota]="socioType == 'socio'">
      <span><i class="ti-user"></i><strong class="ml-3">Sócio</strong><span>&nbsp;&nbsp;&nbsp; {{ "12" | currency :
          "EUR" }}/ano</span></span>
    </li>
    <br />
    <li class="list-group-item" (click)="selectSocioType($event, 'parceiro')"
      [class.activeQuota]="socioType == 'parceiro'">
      <span><i class="ti-briefcase"></i><strong class="ml-3">Parceiro</strong><span>&nbsp;&nbsp;&nbsp; {{ "50" |
          currency : "EUR" }}/ano</span>&nbsp;<span class="small">(pessoa coletiva)</span></span>
    </li>
    <br />
  </ul>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <button class="btn btn-primary w-100" (click)="nextStep()" [disabled]="socioType == ''">
        Próximo
      </button>
    </div>
  </div>
  <div class="clearfix mb-5"></div>
</div>
<div class="container mt-3 quotas" *ngIf="step == 2">
  <p>Preencha as informações necessárias</p>
  <p class="small">passo {{ step }} de {{ maxsteps }}</p>
  <ul class="list-group">
    <div class="input-group w-100 has-validation">
      <input id="name" type="text" class="form-control" placeholder="{{
          socioType == 'socio' ? 'Nome' : 'Designação Comercial'
        }}" name="name" [(ngModel)]="name" (blur)="validateName()" required />
      <div class="invalid-feedback">Por favor indique o seu nome</div>
    </div>
    <div class="row mt-3">
      <div [ngClass]="socioType == 'socio' ? 'col-6' : 'col-12'">
        <div class="input-group w-100 has-validation">
          <input id="nif" type="text" class="form-control" placeholder="{{ socioType == 'socio' ? 'NIF' : 'NPC' }}"
            name="nif" [(ngModel)]="nif" (blur)="validateFormNIF()" required />
          <div class="invalid-feedback">
            {{ nifInvalidFeed }}
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="socioType == 'socio'">
        <div class="input-group w-100 has-validation">
          <input id="nic" type="text" class="form-control" placeholder="CC" name="nic" [(ngModel)]="nic"
            (blur)="validateNIC()" required />
          <div class="invalid-feedback">
            {{ nicInvalidFeed }}
          </div>
        </div>
      </div>
    </div>

    <div class="input-group w-100 mt-3 has-validation">
      <input id="morada" type="text" class="form-control" placeholder="Morada" name="morada" [(ngModel)]="morada"
        (blur)="validateMorada()" required />
      <div class="invalid-feedback">Por favor indique uma morada.</div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <div class="input-group w-100 has-validation">
          <input id="codigoPostal" type="text" class="form-control" placeholder="Código Postal" name="codigoPostal"
            [(ngModel)]="codigoPostal" (blur)="validateCodigoPostal()" required />
          <div class="invalid-feedback">
            Por favor indique um código postal válido (xxxx-xxx).
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group w-100 has-validation">
          <input id="localidade" type="text" class="form-control" placeholder="Localidade" name="localidade"
            [(ngModel)]="localidade" (blur)="validateLocalidade()" required />
          <div class="invalid-feedback">Por favor indique uma localidade.</div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <div class="input-group w-100 has-validation">
          <input id="date" type="text" max="{{ formMaxDate }}" class="form-control" placeholder="{{
              socioType == 'socio' ? 'Data de Nascimento' : 'Data de Fundação'
            }}" name="dataNascimento" [(ngModel)]="dataNascimento" (blur)="validateData()"
            (click)="swapInputType($event, 'date')" />
          <div class="invalid-feedback">
            Por favor indique uma data de nascimento válida.
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group w-100 has-validation">
          <input id="tlm" type="text" min="910000000" class="form-control" placeholder="Telemóvel" name="tlm"
            [(ngModel)]="tlm" (blur)="validateTelemovel()" />
          <div class="invalid-feedback">
            Por favor indique um contacto válido.
          </div>
        </div>
      </div>
    </div>

    <div class="input-group w-100 mt-3 has-validation">
      <input id="email" type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="email"
        (blur)="validateEmail()" />
      <div class="invalid-feedback">Por favor indique um email válido.</div>
    </div>
    <p class="mt-3">
      Ao submeter esta inscrição declaro que li, compreendo e aceito os
      <a href="../../assets/docs/ASCVD.ESTATUTOS.pdf" target="_blank">Estatutos da Associação Vale d'Ouro</a>
      assim como o seu
      <a href="../../assets/docs/ASCVD.REG.INTERNO.pdf" target="_blank">Regulamento</a>
      e a sua
      <a href="../../assets/docs/ASCVD.REG.PRIVACIDADE.pdf">Política de Privacidade e Proteção de Dados Pessoais</a>.
    </p>
  </ul>

  <div class="row mt-3">
    <div class="col-xl-4">
      <button class="btn btn btn-outline-warning w-100 mb-1" (click)="stepBack()">
        Voltar
      </button>
    </div>
    <div class="col-xl-4"></div>
    <div class="col-xl-4">
      <button class="btn btn-primary w-100 mb-5" (click)="validateFormData()">
        Seguinte
      </button>
    </div>
  </div>

  <div class="clearfix mb-5"></div>
</div>

<div class="container mt-3 quotas" *ngIf="step == 3">
  <p>Escolhe a forma de pagamento</p>
  <p class="small">passo {{ step }} de {{ maxsteps }}</p>
  <ul class="list-group">
    <li class="list-group-item" (click)="selectPayMethod($event, 'mb')" [class.activeQuota]="payMethod == 'mb'">
      <span><img src="../../../assets/img/mb_logo.png" width="50px" alt="" /><strong class="ml-3">Referência
          Multibanco</strong><span *ngIf="payMethod == 'mb'" class="small">&nbsp;&nbsp;&nbsp; Os dados de pagamento
          ficam disponíveis na
          confirmação da encomenda.</span></span>
    </li>
    <br />
    <li class="list-group-item" (click)="selectPayMethod($event, 'mbw')" [class.activeQuota]="payMethod == 'mbw'">
      <span><img src="../../../assets/img/mbway_logo.png" width="50px" alt="" /><strong class="ml-3">MB
          WAY</strong><span *ngIf="payMethod == 'mbw'" class="small">&nbsp;&nbsp;&nbsp; Para finalizar introduza o
          número de telemóvel
          associado ao MB WAY.</span></span>
      <div class="input-group w-100" *ngIf="payMethod == 'mbw'">
        <div class="input-group-prepend">
          <span class="input-group-text" id=""><img src="../../../assets/img/flag_pt.png" width="25px"
              alt="" />&nbsp;+351</span>
        </div>
        <input type="text" class="form-control" placeholder="Telemóvel" name="mbwNR" [(ngModel)]="mbwNR"
          (keyup)="validatePaymentOptions()" />
      </div>
    </li>
    <br />
  </ul>
  <div class="row">
    <div class="col-md-4">
      <button class="btn btn btn-outline-warning w-100 mb-1" (click)="stepBack()">
        Voltar
      </button>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <button class="btn btn-primary w-100" (click)="pay()" [disabled]="!isValidPayOptions">
        Confirmar
      </button>
    </div>
  </div>
  <div class="clearfix mb-5"></div>
</div>

<div class="container mt-3 donation" *ngIf="isFinalStep">
  <img src="../../assets/img/logoCompleto.png" alt="" width="350px" class="print-no_display" /><br
    class="print-no_display" /><br class="print-no_display" />
  <p class="alert" *ngIf="payMethod == 'mb'">
    Os dados abaixo foram enviados para o e-mail indicado e não estarão
    disponíveis para consulta mais tarde nesta página! Caso pretenda guardar
    estes dados imprima a página ou consulte o seu e-mail.
  </p>

  <div *ngIf="payMethod == 'mb'" class="mt-3">
    <h6 class="print-h1">Obrigado por se ter juntado a nós!</h6>
    <div class="print-text">
      <img src="../../assets/img/mb_logo.png" width="100px" alt="" class="print-img" /><br />
      <table style="margin-left: auto; margin-right: auto">
        <tr>
          <td class="font-weight-bold">Entidade:</td>
          <td>{{ entity }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Referência:</td>
          <td>{{ ref }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Valor:</td>
          <td>{{ value | currency : "EUR" }}</td>
        </tr>
      </table>

      <span class="small">Referência válida até {{ maxDate | date }} </span><br /><br />

      <p>
        Quando confirmado o pagamento receberá as restantes informações no seu
        e-mail.
      </p>
      <button class="btn btn-info" onclick="window.print();return false;">
        Imprimir&nbsp;&nbsp;&nbsp;<i class="ti-printer"></i>
      </button>
      <div style="min-height: 75px"></div>
    </div>
  </div>
  <div *ngIf="payMethod == 'mbw'" class="mt-3">
    <div style="min-height: 75px"></div>
    <img src="../../assets/img/mbway_logo.png" width="100px" alt="" class="print-img" /><br />
    <h6 class="print-h1">Obrigado pelo seu contributo</h6>
    <p>
      Foi enviado para o telemóvel <strong>{{ mbwNR }}</strong> o pedido de
      pagamento.
    </p>
    <p class="small">Dispõe de 4 minutos para efetuar o pagamento.</p>
    <div style="min-height: 210px"></div>
  </div>

  <div class="clearfix mb-5"></div>
</div>
<!-- /notice -->
<app-footer></app-footer>