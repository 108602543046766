import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { LeadService } from "../services/lead.service";

@Component({
  selector: "app-novo-socio",
  templateUrl: "./novo-socio.component.html",
  styleUrls: ["./novo-socio.component.css"],
})
export class NovoSocioComponent implements OnInit {
  isLoading: boolean = true;

  step: number = 1;
  maxsteps: number = 3;
  isFinalStep: boolean = false;

  codigoPostal: string;
  morada: string;
  localidade: string;
  dataNascimento: string;
  tlm: any;
  nic: any;
  nicInvalidFeed:String = "Por favor indique um número de CC válido (XXXXXXXX X XXX).";
  formMaxDate: any = new Date().toISOString().split("T")[0];

  socioType: String = "";
  payMethod: String;
  isValid: boolean = false;
  isValidPayOptions: boolean = false;
  mbwNR: any;
  ref: string;
  entity: string;
  value: number;
  maxDate: Date;

  name: string = "";
  nif: string = "";
  nifInvalidFeed:string="Por favor indique um NIF válido.";
  email: string = "";

  disableSumbit : boolean = false;

  constructor(
    private notifierService: NotifierService,
    private leadService: LeadService
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  nextStep() {
    this.step += 1;
  }

  stepBack() {
    this.step -= 1;
  }

  swapInputType(e, type) {
    e.target.type = type;
  }

  selectSocioType(event, type) {
    this.socioType = type;

    let elements = document.getElementsByClassName("activePayMethod");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("activePayMethod");
    }

    let element = event.target;
    while (element.nodeName != "LI") {
      element = element.parentElement;
    }

    element.classList.add("activePayMethod");
  }

  validateName() {
    if (this.name.length < 3) {
      this.setValidStatus("name", false);
      return false;
    } else {
      this.setValidStatus("name", true);
      return true;
    }
  }
  
  async validateFormNIF() {
    let res = false;
    if (!this.nif || !this.validateNIF(this.nif)) {
      this.nifInvalidFeed = "Por favor indique um NIF válido.";
      this.setValidStatus("nif", false);
      return false;
    } else {
      this.setValidStatus("nif", true);
      const response:any = await this.leadService.checkNICNIF(this.nic, this.nif).toPromise();

      if(response.nifExists){
        this.nifInvalidFeed="Sócio existente.";
        this.setValidStatus("nif", false);
        return false;
      }else{
        return true;
      }
      
    }
  }

  async validateNIC() {

    if (
      this.socioType == "socio" &&
      (!this.nic || !this.validateCC(this.nic.replace(/\s/g, "")))
    ) {

      this.nicInvalidFeed = "Por favor indique um número de CC válido (XXXXXXXX X XXX).";
      this.setValidStatus("nic", false);
      return false;

    } else if (this.socioType == "socio") {

      this.setValidStatus("nic", true);
      const response:any = await this.leadService.checkNICNIF(this.nic, this.nif).toPromise();

      if(response.nicExists){
        this.nicInvalidFeed="Sócio existente.";
        this.setValidStatus("nic", false);
        return false;
      }else{
        return true;
      }
      
    }

    
  }

  validateMorada() {
    if (this.morada == undefined || this.morada.length < 7) {
      this.setValidStatus("morada", false);
      return false;
    } else {
      this.setValidStatus("morada", true);
      return true;
    }
  }

  validateCodigoPostal() {
    var codigoPostalRx = /^\d{4}-\d{3}?$/;

    if (!this.codigoPostal || !this.codigoPostal.match(codigoPostalRx)) {
      this.setValidStatus("codigoPostal", false);
      return false;
    } else {
      this.setValidStatus("codigoPostal", true);
      return true;
    }
  }

  validateLocalidade() {
    if (this.localidade == undefined || this.localidade.length < 2) {
      this.setValidStatus("localidade", false);
      return false;
    } else {
      this.setValidStatus("localidade", true);
      return true;
    }
  }

  validateData() {
    if (this.dataNascimento == undefined || !this.isValidDate(this.dataNascimento)) {
      this.setValidStatus("date", false);
      return false;
    }

    let dateSelected = new Date(this.dataNascimento);

    const today = new Date();

    today.setHours(0, 0, 0, 0);
    dateSelected.setHours(0, 0, 0, 0);

    if (dateSelected > today) {
      this.setValidStatus("date", false);
      return false;
    } else {
      this.setValidStatus("date", true);
      return true;
    }
  }

  isValidDate(dtValue2:any) {
    var pattern = /\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/g;
    var m = dtValue2.match(pattern);
    if (!m)
      return false;
    
    return true;

  }

  validateTelemovel() {
    let availablePrefix = ["91", "92", "93", "96"];
    if (
      !this.tlm ||
      this.tlm.length != 9 ||
      !availablePrefix.includes(this.tlm[0] + this.tlm[1])
    ) {
      this.setValidStatus("tlm", false);
      return false;
    } else {
      this.setValidStatus("tlm", true);
      return true;
    }
  }

  validateEmail() {
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!this.email || !this.email.match(emailRegex)) {
      this.setValidStatus("email", false);
      return false;
    } else {
      this.setValidStatus("email", true);
      return true;
    }
  }

  async validateFormData() {
    if (
      this.validateName() &&
      await this.validateFormNIF() &&
      (this.socioType == "parceiro" || await this.validateNIC()) &&
      this.validateMorada() &&
      this.validateCodigoPostal() &&
      this.validateLocalidade() &&
      this.validateData() &&
      this.validateTelemovel() &&
      this.validateEmail()
    ) {
      this.isValid = true;
      this.step += 1;
      this.mbwNR = this.tlm;
      return true;
    } else {
      this.isValid = false;
      return false;
    }
  }

  validatePaymentOptions() {
    if (this.step == 3) {
      if (this.payMethod != "mb" && this.payMethod != "mbw") {
        this.isValidPayOptions = false;
        return false;
      }

      if (this.payMethod == "mbw") {
        let availablePrefix = ["91", "92", "93", "96"];
        if (
          !this.mbwNR ||
          this.mbwNR.length != 9 ||
          !availablePrefix.includes(this.mbwNR[0] + this.mbwNR[1])
        ) {
          this.isValidPayOptions = false;
          //toast erro
          return false;
        }
      }
    }
    this.isValidPayOptions = true;
  }

  setValidStatus(eName, isValid) {
    let element = document.getElementById(eName);
    if (isValid) {
      element.classList.remove("is-invalid");
      element.classList.add("is-valid");
    } else {
      element.classList.remove("is-valid");
      element.classList.add("is-invalid");
    }
  }

  selectPayMethod(event, paymethod) {
    this.payMethod = paymethod;

    let elements = document.getElementsByClassName("activePayMethod");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("activePayMethod");
    }

    let element = event.target;
    while (element.nodeName != "LI") {
      element = element.parentElement;
    }

    element.classList.add("activePayMethod");

    this.validatePaymentOptions();
  }

  pay() {
    this.isLoading = true;

    this.leadService
      .createLead(
        this.nif,
        this.name,
        this.nic,
        this.morada,
        this.codigoPostal,
        this.localidade,
        this.email,
        this.tlm,
        this.dataNascimento,
        this.socioType,
        this.payMethod,
        this.mbwNR
      )
      .subscribe((res: any) => {
        if (res.status) {
          if (this.payMethod == "mb") {
            this.entity = res.entity;
          }
          this.value = res.value;
          this.ref = res.ref;
          this.maxDate = res.maxDate;

          this.step = 0;
          this.isFinalStep = true;
          this.isLoading = false;
        } else {
          this.notifierService.notify(
            "error",
            "Não foi possível processar o seu pedido. Se o problema persistir contact o administrador do site."
          );
        }
      });
  }

  validateNIF(nif: string): boolean {
    if (
      nif.length != 9 ||
      (!["1", "2", "3", "5", "6", "8"].includes(nif.substr(0, 1)) &&
        !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(
          nif.substr(0, 2)
        ))
    ) {
      return false;
    }
    const total =
      Number(nif[0]) * 9 +
      Number(nif[1]) * 8 +
      Number(nif[2]) * 7 +
      Number(nif[3]) * 6 +
      Number(nif[4]) * 5 +
      Number(nif[5]) * 4 +
      Number(nif[6]) * 3 +
      Number(nif[7]) * 2;
    const modulo11 = total - Math.trunc(total / 11) * 11;
    const comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11;
    return Number(nif[8]) === comparador;
  }

  validateCC(numeroDocumento) {
    let sum = 0;
    let secondDigit = false;

    if (numeroDocumento.length !== 12) {
      return false;
    }

    for (let i = numeroDocumento.length - 1; i >= 0; --i) {
      try {
        let valor = this.getNumberFromChar(numeroDocumento[i]);

        if (secondDigit) {
          valor *= 2;
          if (valor > 9) {
            valor -= 9;
          }
        }

        sum += valor;
        secondDigit = !secondDigit;
      } catch (e) {
        return false;
      }
    }

    return sum % 10 === 0;
  }

  getNumberFromChar(letter) {
    switch (letter.toUpperCase()) {
      case "0":
        return 0;
      case "1":
        return 1;
      case "2":
        return 2;
      case "3":
        return 3;
      case "4":
        return 4;
      case "5":
        return 5;
      case "6":
        return 6;
      case "7":
        return 7;
      case "8":
        return 8;
      case "9":
        return 9;
      case "A":
        return 10;
      case "B":
        return 11;
      case "C":
        return 12;
      case "D":
        return 13;
      case "E":
        return 14;
      case "F":
        return 15;
      case "G":
        return 16;
      case "H":
        return 17;
      case "I":
        return 18;
      case "J":
        return 19;
      case "K":
        return 20;
      case "L":
        return 21;
      case "M":
        return 22;
      case "N":
        return 23;
      case "O":
        return 24;
      case "P":
        return 25;
      case "Q":
        return 26;
      case "R":
        return 27;
      case "S":
        return 28;
      case "T":
        return 29;
      case "U":
        return 30;
      case "V":
        return 31;
      case "W":
        return 32;
      case "X":
        return 33;
      case "Y":
        return 34;
      case "Z":
        return 35;
    }
    throw new Error("Valor inválido no número de documento.");
  }
}
