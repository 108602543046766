import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {

  activeView = "socios";
  viewController : any = [{
    name:"socios",
    state:true
  },
  {
    name:"leads",
    state:false
  },{
    name:"donativos",
    state:false
  },{
    name:"socios_single",
    state:false
  },{
    name:"bilhetes",
    state:false
  },{
    name:"eventos",
    state:false
  }];


  constructor(private router : Router, private route : ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe( (params) => {

      if(this.router.url.includes("/admin/panel/socios")){
        if(params.nrSocio){
          this.setView("socios_single");
        }else{
          this.setView("socios");
        }

      }else if(this.router.url.includes("/admin/panel/leads")){
        this.setView("leads");

      }else if(this.router.url.includes("/admin/panel/donativos")){
        this.setView("donativos");

      }else if(this.router.url.includes("/admin/panel/bilhetes")){
        this.setView("bilhetes");

      }else if(this.router.url.includes("/admin/panel/eventos")){
        this.setView("eventos");

      }

    });

    
  }

  setView(view){
    this.viewController.forEach(element => {
      if(view === element.name){
        element.state = true;
        this.activeView = view;
      }else{
       element.state = false
      }
    });

  }

}
