<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <ul class="list-inline custom-breadcrumb">
            <li class="list-inline-item"><a class="h2 text-primary font-secondary">Notícias</a></li>
            <li class="list-inline-item text-white h3 font-secondary @@nasted"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- /page title -->

<!-- blogs -->
<section class="section">
    <div class="container">
      <div class="row">
        <!-- blog post -->
        <article class="col-lg-4 col-sm-6 mb-5" *ngFor="let newsEntry of newsList">
          <div class="card rounded-0 border-bottom border-primary border-top-0 border-left-0 border-right-0 hover-shadow">
            <img class="card-img-top rounded-0" src="{{newsEntry.image}}" alt="Post thumb">
            <div class="card-body">
              <!-- post meta -->
              <ul class="list-inline mb-3">
                <!-- post date -->
                <li class="list-inline-item mr-3 ml-0">{{newsEntry.createdAt | date:'fullDate' }}</li>
              </ul>
              <a routerLink="{{newsEntry.uuid}}">
                <h4 class="card-title">{{newsEntry.title}}</h4>
              </a>
              <p class="card-text" [innerHTML]="newsEntry.short_description"></p>
              <a routerLink="{{newsEntry.uuid}}" class="btn btn-primary btn-sm">Ver mais</a>
            </div>
          </div>
        </article>
        <!-- blog post -->
      </div>
    </div>
  </section>
  <!-- /blogs -->
  <app-footer></app-footer>