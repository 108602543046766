<h2>Donativos</h2>
<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">Token</th>
        <th scope="col">Nome</th>
        <th scope="col">NIF</th>
        <th scope="col">email</th>
        <th scope="col">Total</th>
        <th scope="col">Estado</th>
        <th scope="col">Data criação</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let donation of donations">
        <td>{{donation.token}}</td>
        <td>{{donation.name}}</td>
        <td>{{donation.nif}}</td>
        <td>{{donation.email}}</td>
        <td>{{donation.total | currency:'EUR'}}</td>
        <td>{{donation.is_paid | pagaNaoPaga}}</td>
        <td>{{donation.createdAt | date:'MMM d, y'}}</td>
      </tr>
    </tbody>
  </table>
</div>