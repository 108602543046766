<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <ul class="list-inline custom-breadcrumb">
                    <li class="list-inline-item"><a class="h2 text-primary font-secondary">Gestão de Destaques</a></li>
                    <li class="list-inline-item text-white h4 font-secondary @@nasted"></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div class="container mt-3">
    <div class="row">
        <div class="col-xl-12">
            <h3>Criar destaque</h3>
            <hr />
            <div class="row" (change)="handleUpdateNewHighlight()">
              <div class="col-xl-6">
                <div class="form-group">
                  <h4>Título</h4>
                  <input type="text" class="form-control" placeholder="" [(ngModel)]="highlightObject.title" maxlength="50"/>   
                  <small class="form-text text-muted">Máx. XX car.</small>  
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <h4>Link redirecionamento</h4>
                  <input type="url" class="form-control" placeholder="https://..." [(ngModel)]="highlightObject.destination_url" />     
                </div>
              </div>

              <div class="col-xl-12">
                <div class="mb-3">
                  <h4>Descrição</h4>
                  <textarea type="text" class="form-control" placeholder="" [(ngModel)]="highlightObject.description" maxlength="250" cols="3" style="height: 125px;"></textarea>
                  <small class="form-text text-muted">Máx. 250 car.</small>  
                </div>
              </div>
              <div class="col-xl-4 ">
                <div class="form-group">
                  <h4>Ordem</h4>
                  <input type="number" class="form-control" [(ngModel)]="highlightObject.display_order"/>   
                </div>
              </div>
              <div class="col-xl-4 text-center">
                <div class="form-group">
                  <h4>Ativo</h4>
                  <input type="checkbox" class="form-control" [(ngModel)]="highlightObject.is_active"/>   
                </div>
              </div>
              <div class="col-xl-4 text-center">
                <div class="form-group">
                  <h4>Default</h4>
                  <input type="checkbox" class="form-control" [(ngModel)]="highlightObject.is_default"/>   
                </div>
              </div>
              <div class="col-xl-12">
                <button class="btn btn-success w-100" [disabled]="createButtonDisabled" (click)="create()">Criar</button>
              </div>
            </div>  
            
            
            
            
            
            
        </div>
        <div class="col-xl-12 mt-5">
            <h4>Gerir destaques</h4>
            <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Ordem</th>
                    <th scope="col">Título</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">URL Destino</th>
                    <th scope="col">Ativo</th>
                    <th scope="col">Default</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let highlight of highLightsLst" (change)="handleHighlightChange(highlight.uuid)" [ngClass]="{'edited': highlight.pending_changes}">       
                      <th scope="row"><input class="form-control" type="number" [(ngModel)]="highlight.display_order" value="{{highlight.display_order}}" /></th>
                      <td><textarea type="text" class="form-control" style="height: 100px;" [(ngModel)]="highlight.title" maxlength="50">{{highlight.title}}</textarea></td>
                      <td><textarea type="text" class="form-control" style="height: 100px;" [(ngModel)]="highlight.description" maxlength="250">{{highlight.description}}</textarea></td>
                      <td><textarea type="text" class="form-control" style="height: 100px;" [(ngModel)]="highlight.destination_url">{{highlight.destination_url}}</textarea></td>
                      <td class="text-center"><input class="form-check-input" type="checkbox" [(ngModel)]="highlight.is_active" [checked]="highlight.is_active" /></td>
                      <td class="text-center"><input class="form-check-input" type="checkbox" [(ngModel)]="highlight.is_default" [checked]="highlight.is_default" /></td>
                      <th><button class="btn btn-info btn-sm" *ngIf="highlight.pending_changes" (click)="save(highlight.uuid)">Guardar</button></th>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
</div>
<app-footer></app-footer>