<section class="bg-gray overflow-md-hidden">
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-xl-4 col-lg-5 align-self-end">
          <img class="img-fluid" style="width: 97.55%;" src="../../assets/img/IMG_7151.jpg" alt="banner-feature">
        </div>
        <div class="col-xl-8 col-lg-7">
          <div class="row feature-blocks bg-gray justify-content-between">
            <div class="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-left">
              <span class="iconify" data-icon="carbon:train-speed" style="font-size:60px"></span>
              <h3 class="mb-xl-4 mb-lg-3 mb-4">INTERVENÇÃO CÍVICA</h3>
              <p>A Linha de Alta Velocidade Porto-Madrid via Trás-os-Montes e a reabertura da Linha do Douro até Salamanca constituem os grandes desafios da região.
              </p>
              <!--a href="contact.html" class="btn btn-primary" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7">Saber mais</a-->
            </div>
            <div class="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-left">
              <span class="iconify" data-icon="maki:theatre" style="font-size:60px"></span>
              <h3 class="mb-xl-4 mb-lg-3 mb-4">CULTURA</h3>
              <p>A Mostra de Teatro do Douro que se realiza desde 2009 e o Grupo de Teatro são as grandes apostas da associação. A Rede de Associações é um projeto em desenvolvimento que conta com o apoio das entidades da economia social do Douro.
              </p>
              <!--a href="contact.html" class="btn btn-primary" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7">Saber mais</a-->
            </div>
            <div class="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-left">
              <span class="iconify" data-icon="bx:football" style="font-size:60px"></span>
              <h3 class="mb-xl-4 mb-lg-3 mb-4">DESPORTO</h3>
              <p>A Academia de Futsal proporciona um quadro de competição sénior e formação aos mais jovens no concelho de Alijó
              </p>
              <!--a href="contact.html" class="btn btn-primary" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7">Saber mais</a-->
            </div>
            <div class="col-sm-6 col-xl-5 mb-xl-5 mb-lg-3 mb-4 text-center text-sm-left">
              <span class="iconify" data-icon="fluent:people-community-add-28-regular" style="font-size:60px"></span>
              <h3 class="mb-xl-4 mb-lg-3 mb-4">SOCIAL</h3>
              <p>Um dos pilares estatutários da instituição e em que se prepara, no próximo quadro estratégico, um reforço da atividade
              </p>
              <!--a href="contact.html" class="btn btn-primary" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7">Saber mais</a-->
            </div>
            
          </div>
        </div>
        
      </div>
    </div>
  </section>
  