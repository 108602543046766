<app-loader [isOpen]="isLoading"></app-loader>
<section *ngIf="ticketLst" class="container mt-5">
    <h2>Relatório de Assistência</h2>
    <h4>{{eventInfo.type_event_type.type}}</h4>
    <div class="row">
        <div class="col-xl-6 col-print-6 text-left clearfix">
            <p>{{eventInfo.title}}</p>
            <p>{{eventInfo.venue_venue.name}}</p>
            <p>{{eventInfo.start_date | date:'dd MMMM yyyy | HH:mm'}}</p>
        </div>
        <div class="col-xl-6 col-print-6 text-right">
            <img src="{{eventInfo.team_a_team.logo}}" alt="" width="75px"><strong>&nbsp;vs&nbsp;</strong>
            <img src="{{eventInfo.team_b_team.logo}}" alt="" width="74px">
        </div>
    </div>
    <hr>
    
    <div class="row">     
        <div class="col-xl-6 col-print-6 text-left clearfix">
            <h3>Totais</h3>
            <table class="table">
                <tr>
                    <td>Bilhetes Emitidos</td>
                    <td>{{soldTickets}}</td>
                </tr>
                <tr>
                    <td>Bilhetes Usados</td>
                    <td>{{usedTickets}}</td>
                </tr>
            </table>
            <h3>Totais por Bancada</h3>
            <table class="table">
                <thead>
                    <th>Bancada</th>
                    <th>Emitidos</th>
                    <th>Usados</th>
                </thead>
                <tr *ngFor="let stand of ticketByStandMap | keyvalue">
                    <td>{{stand.key}}</td>
                    <td>{{stand.value.tickets.length}}</td>
                    <td>{{stand.value.used}}</td>
                </tr>
            </table>

        </div>
        <div class="col-xl-6 col-print-6 text-left clearfix">
            <h3>Tipologia Assistência</h3>
            <table class="table">
                <thead>
                    <th>Tipo</th>
                    <th>Emitidos</th>
                    <th>Usados</th>
                </thead>
                <tr *ngFor="let type of ticketByPayMehtod | keyvalue">
                    <td>{{type.key}}</td>
                    <td>{{type.value.tickets.length}}</td>
                    <td>{{type.value.used}}</td>
                </tr>
            </table>

        </div>
        
    </div>
    <div *ngIf="ticketLst.length > 0" class="row">    
        <div class="col-xl-12 col-print-12"> 
            <h5>Bilhetes</h5>
            <table class="table">
                <thead>
                    <th>Id</th>
                    <th>Tipo Bilhete</th>
                    <th>NIF</th>
                    <th>Token</th>
                </thead>
                <tbody>
                    <tr *ngFor="let ticket of ticketLst">
                        <td>{{ticket.qr_id}}</td>
                        <td>{{ticket.pay_method}}</td>
                        <td>{{ticket.nif}}</td>
                        <td>{{ticket.token}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="mbwTokenLst.length > 0" class="row">    
        <div class="col-xl-12 col-print-12"> 
            <h5>MBW Tokens</h5>
            <p *ngFor="let token of mbwTokenLst">{{token}}</p>
        </div>
    </div> 
</section>

<section *ngIf="!ticketLst" class="container mt-5">
    <h2>Relatório de Assistência</h2>
    <h4>{{eventInfo.type_event_type.type}}</h4>
    <div class="row">
        <div class="col-xl-6 col-print-6 text-left clearfix">
            <p>{{eventInfo.title}}</p>
            <p>{{eventInfo.venue_venue.name}}</p>
            <p>{{eventInfo.start_date | date:'dd MMMM yyyy | HH:mm'}}</p>
        </div>
        <div class="col-xl-6 col-print-6 text-right">
            <img src="{{eventInfo.team_a_team.logo}}" alt="" width="75px"><strong>&nbsp;vs&nbsp;</strong>
            <img src="{{eventInfo.team_b_team.logo}}" alt="" width="74px">
        </div>
    </div>
    <hr>
    <h4>Sem dados de Assistência</h4>
</section>