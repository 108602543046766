<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <ul class="list-inline custom-breadcrumb">
            <li class="list-inline-item"><a class="h2 text-primary font-secondary" href="@@page-link">Contactos</a></li>
            <li class="list-inline-item text-white h3 font-secondary @@nasted"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- /page title -->
  
  <!-- teachers -->
  <section class="section">
    <div class="container">
        <img src="../../assets/img/logoCompleto.png" alt="" class="w-25">
        <div class="mt-3" style="padding-left: 10px;">
            <p class="h5">Rua António Manuel Saraiva, 57</p>
            <p class="h5">Edificio Casa do Povo do Pinhão</p>
            <p class="h5">5085-037 Pinhão</p>
            <br/>
            <p><span class="iconify" data-icon="material-symbols:phone-enabled" style="font-size:30px" data-inline="true"></span> <a href="tel:+351934392617">&nbsp;&nbsp;&nbsp;+351 93 439 26 17</a></p>
            <p><span class="iconify" data-icon="material-symbols:phone-enabled" style="font-size:30px" data-inline="true"></span> <a href="tel:+351934392617">&nbsp;&nbsp;&nbsp;+351 96 232 57 62</a></p>

            <p><span class="iconify" data-icon="ic:baseline-email" style="font-size:30px" data-inline="true"></span> <a href="mailto:geral@associacaovaledouro.pt?subject=Portal - Formulário de Contacto">&nbsp;&nbsp;&nbsp;geral@associacaovaledouro.pt</a></p>
            
            <iframe
            class="w-100"
            height="400"
            style="border:0"
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDl89o6hDjSbDz30eTOxhIoHyIpVvJ_GYA&q=Associação+Vale+d'Ouro">
            </iframe>
    
        </div>


    </div>
   
  </section>
  <!-- /teachers -->
  <app-footer></app-footer>