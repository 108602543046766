<h2>Leads</h2>
<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">Token</th>
        <th scope="col">Nome</th>
        <th scope="col">NIF</th>
        <th scope="col">Email</th>
        <th scope="col">Morada</th>
        <th scope="col">Convertida</th>
        <th scope="col">Data criação</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let lead of leads">
        <td>{{lead.token}}</td>
        <td>{{lead.nome}}</td>
        <td>{{lead.nif}}</td>
        <td>{{lead.email}}</td>
        <td>{{lead.morada}}</td>
        <td>{{lead.convertida | simNao}}</td>
        <td>{{lead.createdAt | date:'MMM d, y'}}</td>
      </tr>
    </tbody>
  </table>
</div>