<app-header></app-header>
<section
  class="page-title-section overlay"
  data-background="images/backgrounds/page-title.jpg"
>
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item">
            <a class="h2 text-primary font-secondary"
              >MOSTRA DE TEATRO DO DOURO</a
            >
          </li>
          <li
            class="list-inline-item text-white h3 font-secondary @@nasted"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section class="section pt-4">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 mb-5 mt-0">
            <button class="btn btn-primary" routerLink="/atividades/mostra-teatro">Voltar</button>
            <button class="btn btn-primary-outline float-right ml-1" (click)="nextGroup()">Próximo</button>
            <button class="btn btn-primary-outline float-right" (click)="previousGroup()">Anterior</button>
        </div>
        <div class="col-md-5 mb-5">
          <img class="img-fluid w-100" src="../../../assets/img/fotos_grupos_teatro/{{selectedGroup.photo}}" alt="Foto de {{selectedGroup.name}}">
        </div>
        <div class="col-md-6 mb-5">
          <h3>{{selectedGroup.name}}</h3>
          <h6 class="text-color">{{selectedGroup.full_name}}</h6>
          <p class="mb-5">{{selectedGroup.description}}</p>
          <div class="row">
            <div class="col-xl-12">
              <h4 class="mb-4">PARTICIPAÇÕES NA MOSTRA DE TEATRO DO DOURO:</h4>
              <ul class="list-unstyled pl-4">
                <li *ngFor="let participation of selectedGroup.participations" class="mb-3">{{participation}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h4 class="mb-4">HISTÓRIA</h4>
          <p class="mb-5">{{selectedGroup.history}}</p>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12">
          <h4 class="mb-4">FOTOS</h4>
        </div>
        <!-- course item -->
        <div class="col-lg-4 col-sm-6 mb-5" *ngFor="let photo of selectedGroup.photos">
          <div class="card p-0 border-primary rounded-0 hover-shadow">
            <img class="card-img-top rounded-0" src="../../../assets/img/fotos_grupos_teatro/{{photo}}">
          </div>
        </div>
      </div>

      <button class="btn btn-primary" routerLink="/atividades/mostra-teatro">Voltar</button>
      <button class="btn btn-primary-outline float-right ml-1" (click)="nextGroup()">Próximo</button>
      <button class="btn btn-primary-outline float-right" (click)="previousGroup()">Anterior</button>
    </div>
  </section>


<app-footer></app-footer>