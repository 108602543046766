import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-admin-panel-socios-single',
  templateUrl: './admin-panel-socios-single.component.html',
  styleUrls: ['./admin-panel-socios-single.component.css']
})
export class AdminPanelSociosSingleComponent implements OnInit {

  socio : any;
  isEditing : boolean = false;

  constructor(private adminService : AdminServiceService, private route : ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe( (params) => {
      this.adminService.getSocio(params.nrSocio).subscribe((result)=>{
        this.socio = result;
      });
    });
    
  }


}
