<div class="accordion" id="accordionExample">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h2 class="mb-0">
        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
          Bilhetes
        </button>
      </h2>
    </div>

    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Token</th>
                <th scope="col">VS</th>
                <th scope="col">Nome</th>
                <th scope="col">NIF</th>
                <th scope="col">Email</th>
                <th scope="col">Preço</th>
                <th scope="col">Estado</th>
                <th scope="col">Usado</th>
                <th scope="col">Data Uso</th>
                <th scope="col">Data criação</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ticket of tickets">
                <td>{{ticket.token}}</td>
                <td>{{ticket.event_event.team_b_team.name}}</td>
                <td>{{ticket.name}}</td>
                <td>{{ticket.nif}}</td>
                <td>{{ticket.email}}</td>
                <td>{{ticket.price | currency:'EUR'}}</td>
                <td>{{ticket.is_paid | pagaNaoPaga}}</td>
                <td>{{ticket.is_used | simNao}}</td>
                <td>{{ticket.use_time | date:'MMM d, y H:mm'}}</td>
                <td>{{ticket.createdAt | date:'MMM d, y H:mm'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Bilhetes Época
        </button>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
        <div class="table-responsive">
      <table class="table table-striped table">
        <thead>
          <tr>
            <th scope="col">Token</th>
            <th scope="col">Nr Socio</th>
            <th scope="col">Nome</th>
            <th scope="col">NIF</th>
            <th scope="col">email</th>
            <th scope="col">Estado</th>
            <th scope="col">Data Uso</th>
            <th scope="col">Data criação</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ticket of season_tickets">
            <td>{{ticket.token}}</td>
            <td>{{ticket.socio_socio.nr_socio}}</td>
            <td>{{ticket.socio_socio.nome}}</td>
            <td>{{ticket.socio_socio.nif}}</td>
            <td>{{ticket.socio_socio.email}}</td>
            <td>{{ticket.is_paid | pagaNaoPaga}}</td>
            <td>{{ticket.used_date | date:'MMM d, y H:m'}}</td>
            <td>{{ticket.createdAt | date:'MMM d, y H:m'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
      </div>
    </div>
  </div>
</div>
