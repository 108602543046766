<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item"><a class="h2 text-primary font-secondary">Cartão de Sócio Virtual</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container mt-3 mb-3 quotas">
    <div class="row" *ngIf="socioCardB64 != ''">
        <div class="col-xl-8">
            <img src={{socioCardB64}} alt="" width="100%">
            <p class="small text-justify p-3">
              <strong>INFO LEGAL</strong> <br />
              Este cartão de Sócio é pessoal e intransmissível. O portador pelo simples facto de possuir
              e utilizar este cartão, obriga-se a conhecer o Regulamento Interno da Associação Vale d'Ouro. Poderá ser exigida
              a apresentação de documento oficial com fotografia.
            </p>
        </div>
        <div class="col-xl-4">
            <img src={{qrCodeB64}} alt="" width="100%">
            <p class="small text-justify mt-3">
              <strong>QR CODE</strong> <br />
              A apresentação deste QR Code pode ser utilizada como forma alternativa ao cartão de sócio no momento de autenticação perante os nossos parceiros, colaboradores ou voluntários.
            </p>
            <button class="btn btn-primary w-100 p-3" (click)="generateCardPDF()" [disabled]="pendingAction">Download versão em PDF</button>            
        </div>
    </div>
    <div class="row" *ngIf="socioCardB64 == ''">
      <h3 class="pt-5">Para obter o cartão de sócio deverá regularizar as suas quotas.</h3>
      <h2 class="small">Poderá fazê-lo <a href="/socio/quota/pagar">aqui</a></h2>
    </div>
</div>
<!-- /page title -->


<!-- /notice -->
<app-footer></app-footer>