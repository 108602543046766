import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mostra-teatro-single',
  templateUrl: './mostra-teatro-single.component.html',
  styleUrls: ['./mostra-teatro-single.component.css']
})
export class MostraTeatroSingleComponent implements OnInit {

  groupLst : any = [{
    "id":"da0eb644-b2c1-4bbf-83b7-29766494d53e",
    "photo":"teatraco_main.jpg",
    "name":"Teatraço",
    "full_name":"Teatro Amador de Tabuaço",
    "description":"Encenador: Beto Coville",
    "participations":[
      "2012 (Favaios) – “Precisa-se de um Cadáver”",
      "2013 (Alijó) – “Auto do Bom Despacho”",
      "2019 (Régua) – “Auto do Bom Despacho”",
      "2022 (Régua) – “Precisa-se de um Cadáver”",
      "2023 (Mirandela) – “Precisa-se um Cadáver”",
      "2024 (Régua) – “O Vison Azul”",
    ],
    "history":"O TEATRAÇO nasceu em 2007 da vontade de cada um dos seus elementos fundadores que partilhavam a vontade de criar e apoiar uma ideia que parecia completamente maluca mas que assumiu o projeto mais com o coração que com a razão. O TEATRAÇO conta já com 15 anos e inúmeros trabalhos que não se ficam pelo teatro de Sala. Passam ainda pelo Teatro para a Infância, Teatro de Rua, Animação e a incrível e magnífica experiência no cinema que lhe tem valido diversos prémios. É sob direção de Beto Coville que o grupo tem feito o seu percurso. É uma figura chave e indispensável a este projeto e que tem passado à equipa que dirige com orgulho, os ensinamentos de uma carreira vasta na área do teatro, cinema, televisão e encenação. A comédia tem sido a base de trabalho deste Grupo que tem conquistado o respeito do público nacional e internacional.",
    "photos":[
      "teatraco_01.jpg",
      "teatraco_02.jpg",
      "teatraco_03.jpg",
    ]
 },{
    "id":"8eefbb2c-651c-4edb-a40d-239e3c65f388",
    "photo":"getepepe_main.jpg",
    "name":"Getepepe",
    "full_name":"Getepepe - Teatro Perafita",
    "description":"Encenadora: Cecília Bento",
    "participations":[
      "2014 (Alijó) – “Camarada Mioussov”",
      "2017 (Régua) – “Um morto muito vivo”",
      "2023 (Régua) – “Dois é Bom, Três é Demais”",
      "2024 (Carrazeda de Ansiães) – “Dois é Bom, Três é Demais”",
    ],
    "history":"O Getepepe – Teatro Perafita nasceu em Outubro de 1997 e tinha como objetivo dinamizar o salão paroquial e fomentar a aprendizagem da “mui nobre” arte de Talma. Ao longo destes anos o GETEPEPE faz um trabalho com seniores, Jovens e crianças e tem levado a cena um grande vasto leque de peças em Perafita e itinerância ao longo do país. Galardoado com vários prémios de encenação, cenário, sonoplastia, guarda-roupa, melhor atriz e melhor espetáculo. Em 1998 apresentou “A birra do morto”, e aquilo que parecia uma brincadeira acabou por se tornar numa séria realidade. Além dos espetáculos realizados em Perafita e da itinerância ao longo do país, o Getepepe organiza o seu  Festival de Teatro “PETRA FICTA”, por onde já passaram muitos grupos amadores e atores profissionais.",
    "photos":[
      "getepepe_01.jpg",
      "getepepe_02.jpg",
      "getepepe_03.jpg",
    ]
 },{
  "id":"2b723919-170b-4f56-83c9-10e3a0c8343a",
  "photo":"tfboticas_main.jpg",
  "name":"Teatro Fórum Boticas",
  "full_name":"Teatro Fórum Boticas",
  "description":"Encenador: Hermínio Fernandes",
  "participations":[
    "2014 (Pinhão) – “Só Visto”",
    "2015 (Santa Marta de Penaguião) – “Carai, valha-me Deus”",
    "2016 (Mesão Frio) – “Carai, valha-me Deus”",
    "2017 (Santa Marta de Penaguião) – “Alice, no País das Maravilhas”",
    "2018 (Régua) – “Carai, valha-me Deus”",
    "2019 (Santa Marta de Penaguião) – “Lisístrata, a Greve do Sexo”",
    "2022 (Santa Marta de Penaguião) – “Quem levou teus trinta ramos?”",
    "2024 (Mirandela) – “Reich Parta!”",
  ],
  "history":"O Teatro Fórum Boticas nasceu em 2008, com o espetáculo “Avarias”, construído a partir de textos de Abel Neves, Alexandre O’Neill, José Carlos Dias e Miguel Torga. Desde aí apresentou “Sonho de uma Noite de Verão”, de William Shahespeare (2009), “O Inspetor Geral”, de Nikolai Gogol (2010), “Dona Rosinha, a Solteira”, de Federico Garcia Lorca (2011), “VIP-TB”, de José Carlos Dias, Anton Tchekov, José Régio e António Gedeão (2012), “Além as Estrelas” são a “Nossa Casa”, de Abel Neves (2013) e “Só Visto”, de José Moreno Arenas (2013). Preparou “Carai valha-me Deus”, de José Carlos Barros, escritor nascido em Boticas para 2015 mas cujo sucesso permite que ainda hoje se mantenha em cena. O clássico da Disney “Alice, no país das Maravilhas” chegou aos palcos em 2017 e já em 2018 a adaptação do clássico de Aristófantes, “Lisístrata”. Em 2019 produziu “Quem levou teus trinta ramos” e agora chega ao palco uma adaptação muito atual do clássico de Bertold Brecht “Terror e Miséria do Terceiro Reich” que intitulou de “Reich Parta!”. A principal preocupação deste coletivo não profissional tem sido a de apresentar textos de qualidade, tenham eles autores clássicos ou contemporâneos, desde que os mesmos se lhes afigurem interessantes de conteúdo e simultaneamente capazes de divertir públicos pouco habituados a ver teatro.",
  "photos":[
    "tfboticas_01.jpg",
    "tfboticas_02.jpg",
    "tfboticas_03.jpg",
  ]
},{
  "id":"ce2f4e69-e516-408a-b2b9-6836a7ca13b9",
  "photo":"lordelense_main.jpg",
  "name":"GTCC Lordelense",
  "full_name":"Grupo de Teatro Centro Cultural Loderlense",
  "description":"Encenador: Ricardo Ferreira de Almeida",
  "participations":[
    "2012 (Santa Eugénia) – “O Pinto Calçudo”",
    "2013 (Sanfins do Douro) – “Compro uma mulher e levo duas”",
    "2014 (Peso da Régua) – “O Grande Aldrabão”",
    "2015 (Sabrosa) – “Os Pires de Sacavém”",
    "2016 (Peso da Régua) – “Há horas felizes”",
    "2017 (Mesão Frio) – “Soldado Fanfarrão”",
    "2018 (Sabrosa) – “O Chá de S. Cornélio”",
    "2019 (Pinhão) – “Os Pelintras”",
    "2022 (Sabrosa) – “Haja Luz”",
    "2023 (Carrazeda de Ansiães) – “Chapéus, Salsichas e Cervejas”",
    "2024 (Santa Marta de Penaguião) – “O Café”",
  ],
  "history":"Este grupo de teatro iniciou a sua atividade em 1978 ano em que foi inaugurado o seu edifício/sede, construído quase exclusivamente para o teatro, o qual tem capacidade para 230 lugares sentados. No entanto, por se tratar de um grupo de amadores, a produção de peças não era regular. Só a partir de 2004, embora mantendo a matriz característica de teatro de amadores, mas com a aquisição e oferta por parte das entidades competentes, nomeadamente a Fundação Inatel, passou a dispor de material sonoro e luminotécnico em quantidade e qualidade minimamente suficientes, que permitiu ensaiar e representar pelo menos uma peça por ano. Desde 2004 o Grupo de Teatro do Centro Cultural Lordelense tem apresentado produções regulares e representado as suas peças nas diversas localidades. Ricardo Almeida encenou os trabalhos que o grupo apresentou entre 2017 e 2018. Em 2019 Joaquim Ferreira volta a tomar conta das lides artísticas do grupo. Ricardo Almeida voltou à encenação no grupo após a pandemia.",
  "photos":[
    "lordelense_01.jpg",
    "lordelense_02.jpg",
    "lordelense_03.jpg",
  ]
},{
  "id":"5145f529-a331-4c44-b937-b455af50d07d",
  "photo":"vermoim_main.jpg",
  "name":"NUTEACV",
  "full_name":"Núcleo de Teatro da Associação Cultural de Vermoim",
  "description":"Encenadora: Catarina Gomes",
  "participations":[
    "2018 (Santa Marta de Penaguião) – “Uma cabeça com dois cabritos”",
    "2019 (Mesão Frio) – “Falar Verdade a Mentir”",
    "2022 (Carrazeda de Ansiães) – “O Regresso das Vaginas”",
    "2023 (Castedo do Douro) – “Procura-se Morto… ou Morto”",
    "2024 (Mesão Frio) – “Bons olhos os vejam”",
  ],
  "history":"O Núcleo de Teatro da Associação Cultural de Vermoim (NUTEACV) conta já com diversos trabalhos realizados e temporadas anuais percorrendo grande parte do país. Pautando a sua conduta pela entrega desinteressada ao Teatro Amador e ao intercâmbio cultural, o NUTEACV tem na comédia o seu estilo buscando a reflexão e a mensagem em todos os seus trabalhos.",
  "photos":[
    "vermoim_01.jpg",
    "vermoim_02.jpg",
    "vermoim_03.jpg",
  ]
},{
  "id":"a9f1d8ef-1ad8-4c90-828c-3c187f5dadad",
  "photo":"novelas_main.jpg",
  "name":"Grupo de Teatro de Novelas",
  "full_name":"Trupe Fernando Leal",
  "description":"Encenadora: Joana Barros",
  "participations":[
    "2019 (Carrazeda de Ansiães) – “Ide dar lavagem à Porca”",
    "2023 (Santa Marta de Penaguião) – “Zé abre a pestana!”",
    "2024 (Alijó) – “Zé abre a pestana!”",
  ],
  "history":"O Grupo de Teatro de Novelas foi fundado em 16 de Outubro de 1999 na localidade de Novelas, no concelho de Penafiel por Fernando Leal  que pretendia dinamizar e unir a comunidade atrás da arte teatral. Este grupo de teatro tem desenvolvido a sua atividade na área do teatro de revista com diversas produções levadas a cabo desde a sua fundação. Tem ainda colaborado com o Município de Penafiel na organização de um festival de teatro no concelho.",
  "photos":[
    "novelas_01.jpg",
    "novelas_02.jpg",
    "novelas_03.jpg",
  ]
},{
  "id":"7ef1b811-01fb-4cb3-b799-8548543f2ec3",
  "photo":"lazarim_main.jpg",
  "name":"GTAV",
  "full_name":"Grupo de Teatro Aldeia Verde de Lazarim",
  "description":"Encenadora: Lurdes Silva",
  "participations":[
    "2009 (Pinhão) – “Quem casa quer casa”",
    "2009 (Pinhão) – “31 à Portuguesa”",
    "2010 (Pinhão)",
    "2011 (Casal de Loivos) – “Mas que vida ou morte”",
    "2012 (Alijó) – “A Vizinha do Lado”",
    "2014 (Sabrosa) – “É a Crise que Criséria… este país está na miséria”",
    "2015 (Régua) – “É a Crise que Criséria… este país está na miséria”",
    "2016 (Santa Marta de Penaguião) – “Só é Corno quem quer”",
    "2017 (Sabrosa) – “Os Turistas”",
    "2018 (Mesão Frio) – “Enquanto Ela não Aparece”",
    "2019 (Favaios) – “Não há uma sem duas”",
    "2022 (Alijó) – “O Cupido Apaixonado”",
    "2023 (Pinhão) – “O Cupido Apaixonado”",
    "2024 (Pegarinhos) – “A Beata da Sogra”",
  ],
  "history":"O GTAV nasceu há quase duas décadas do gosto e da paixão do povo de Lazarim pelas artes do palco. Em 1998 o Grupo de Teatro Aldeia Verde é oficialmente formalizado e rapidamente a estrutura criada produz peças de teatro que correm o país. 'Maldição de Mãe', 'Falar Verdade a Mentir', 'Quem casa quer casa' o grande sucesso original e 'É a Crise, que Criséria... este país está na miséria' são alguns dos trabalhos deste grupo. O GTAV organiza ainda anualmente o Festival Nacional de Teatro de Lazarim.",
  "photos":[
    "lazarim_01.jpg",
    "lazarim_02.jpg",
    "lazarim_03.jpg",
  ]
},{
  "id":"ab0412f9-f21f-47ca-919a-dea412ca966e",
  "photo":"tef_main.jpg",
  "name":"TEF",
  "full_name":"Teatro Experimental Flaviense",
  "description":"Encenador: Rufino Martins",
  "participations":[
    "2014 (Santa Marta de Penaguião) – “O Grande Teatro do Mundo”",
    "2015 (Mesão Frio) – “Teatro às Três Pancadas”",
    "2016 (Sabrosa) – “Bailado Russo”",
    "2017 (Pinhão) – “Bailado Russo”",
    "2018 (Alijó) – “Bailado Russo”",
    "2019 (Sabrosa) - ”A Fantástica Caixa Mágica”",
    "2024 (Sabrosa) – “O Milagre no Convento de Santa Maria Joana”",
  ],
  "history":"O TEF – Teatro Experimental Flaviense, CRL é uma Cooperativa Cultural que foi fundada em 20/01/1980. Para além do teatro nas vertentes de produção, representação, formação, festivais e apoio ao renascer do Teatro Popular, realiza outras atividades. É proprietário do Cine Teatro Bento Martins – em Chaves – que é a sua atual sede.  Ao longo dos seus 32 anos de atividade contínua, recebeu vários prémios e menções destacando-se: o Prémio Podium 92, Prémio Alto Tâmega 93, Prémio Podium 99, Prémio Especial do Júri dos Prémios Podium 2005 e a Medalha de Mérito Municipal – Grau Bronze, atribuída pela Câmara Municipal de Chaves em 2001. É um Centro de Cultura e Desporto da Fundação INATEL, Associação Juvenil equiparada inscrita no RNAJ, associado da ADRAT e Membro da sua Direção, associado da Associação Chaves Viva e Membro da sua Direção, associado da FPTA e pertence ao Conselho Geral do Agrupamento de Escolas Dr. Francisco Gonçalves Carneiro. Desde a sua fundação já produziu mais de uma centena de peças de teatro com diferentes registos e géneros.",
  "photos":[
    "tef_01.jpg",
    "tef_02.jpg",
    "tef_03.jpg",
  ]
},{
  "id":"71af3361-35a2-42c3-a5ce-d484bae20360",
  "photo":"tav_main.jpg",
  "name":"TAV",
  "full_name":"Teatro do Ave",
  "description":"Encenador: Afonso Carvalho",
  "participations":[
    "2022 (Mesão Frio) – “Flagrante Delitro”",
    "2023 (Mesão Frio) – “Retalhos da Vida de Um Bêbado”",
    "2024 (Pinhão) – “Caixão Precisa-se”",
  ],
  "history":"O TAV – Teatro do Ave de Vila do Conde surge a 26 de junho de 2019 através da paixão pelo teatro e pela música de todos os seus intervenientes, aliada à vontade de partilhar com o público o bom teatro de amadores que se faz em Vila do Conde. O elenco residente conta já com largos anos de experiência na arte  teatral o que se revelou determinante para o sucesso alcançado pelo grupo. Na génese do projeto esteve a “necessidade de liberdade de expressão, criatividade e amor ao verdadeiro teatro de amadores”. A criação do grupo era um sonho de vilacondenses que desta forma procuram levar o nome da cidade de Vila do Conde a todo o país. A direção artísticas do grupo está a cargo de Afonso Carvalho.",
  "photos":[
    "tav_01.jpg",
    "tav_02.jpg",
    "tav_03.jpg",
  ]
},];

  currentIndex : number = 0;
  selectedGroup : any;

  constructor(private activatedRoute : ActivatedRoute, private router : Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    let activeId = this.activatedRoute.snapshot.params.id;
    while(this.groupLst[this.currentIndex].id != activeId){
      this.currentIndex++;
    }

    this.selectedGroup = this.groupLst[this.currentIndex];
  }

  nextGroup(){
    
    this.currentIndex++;
    if(this.currentIndex == this.groupLst.length){
      this.currentIndex = 0;
    }

    window.location.href='/atividades/mostra-teatro/'+this.groupLst[this.currentIndex].id;
  }

  previousGroup(){
    this.currentIndex--;
    if(this.currentIndex == -1){
      this.currentIndex = this.groupLst.length-1;
    }

    window.location.href='/atividades/mostra-teatro/'+this.groupLst[this.currentIndex].id;
  }

}
