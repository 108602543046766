import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BackOfficeService } from 'src/app/services/back-office.service';
import { EventEmitter } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private readonly notifier: NotifierService;
  
  @ViewChild('closebutton') closebutton;
  @Input() isAdmin: boolean;
  @Output() updateAdmin = new EventEmitter<Boolean>();

  loginPassword = "";
  loginName = "";
  submitDisabled = true;


  constructor(private backOfficeService : BackOfficeService, private notifierService : NotifierService, private router : Router, private sessionService : SessionService) { 
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  validateInput(){
    if(this.loginPassword && this.loginPassword !== "" && this.loginName && this.loginName !== ""){
      this.submitDisabled = false;
    }else{
      this.submitDisabled = true;
    }
  }

  login($event){
    this.notifier.hideAll();
    $event.preventDefault();
    this.backOfficeService.login(this.loginName, this.loginPassword).subscribe((res:any) =>{
      if(res.status){
        this.closebutton.nativeElement.click();        
        localStorage.setItem("ascvdpt_portal", res.token);
        let data = this.sessionService.getJWTData();
        this.notifier.notify("success","Bem-Vindo(a) " + data.name + "!");
        this.isAdmin = true;
        this.updateAdmin.emit(true);
      }
    },
    (error : HttpErrorResponse) => {
      if(error.status == 401 || error.status == 400){
        this.notifier.notify("error","Dados de login inválidos.");

      }else{
        console.log(error);
        this.notifier.notify("error","Algo inesperado aconteceu. Se o problema persistir, contacte o administrador de sistema.");
      }
      
    });
  }

}
