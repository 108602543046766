import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BackOfficeService } from 'src/app/services/back-office.service';
import { SessionService } from 'src/app/services/session.service';
import { SociosService } from 'src/app/services/socios.service';

@Component({
  selector: 'app-pay-quota',
  templateUrl: './pay-quota.component.html',
  styleUrls: ['./pay-quota.component.css']
})
export class PayQuotaComponent implements OnInit {

  constructor(private socioService : SociosService,
     private sessionService: SessionService,
     private notifierService : NotifierService
  ) { }

  isLoading : boolean = true;
  step : number = 1;
  maxsteps : number = 2;
  isFinalStep : boolean = false;
  payTimeOption : any = [6,12,18,24];
  payOptions : any = [{}];
  payMethod : String;
  nrMonthsSelected : number;
  isValid : boolean = false;
  mbwNR : string;
  ref : string;
  entity : string;
  value : number;
  maxDate : Date;
  pendingQuotas : any;

  ngOnInit() {

    this.socioService.checkPendingQuotas().subscribe((res:any)=>{

      if(res.length > 0){
        this.step = 99;
        this.pendingQuotas = res;
      }

    });

    this.socioService.getLastPaidQuota().subscribe((res:any)=>{
      if(res.status){
        
        this.setPayOptions(res.quota[0].data_fim)

        this.isLoading = false;
      }
    });

  }

  private setPayOptions(lastPaidQuota){
    
    let jwtData = this.sessionService.getJWTData();
    let price = 1;
    if(jwtData.tipo_socio == 5){
      price = 4.16;
    }

    this.payOptions = [];

    this.payTimeOption.forEach((nrMonths : any) => {
      let lastPaidQuotaDate = new Date(lastPaidQuota);
      lastPaidQuotaDate.setMonth(lastPaidQuotaDate.getMonth()+nrMonths+1);
      let until = lastPaidQuotaDate.getFullYear()+"-"+lastPaidQuotaDate.getMonth()+"-01";
      this.payOptions.push({
        months : nrMonths,
        until : until,
        price : price, 
        totalPrice : Math.ceil(nrMonths * price)
      });
    });
    

  }

  activate(event, nrMonths){

    let elements = document.getElementsByClassName("activeQuota");
    for(let i = 0; i < elements.length; i++){
      elements[i].classList.remove("activeQuota");
    }
    this.nrMonthsSelected = nrMonths;

    let element = event.target;
    while(element.nodeName != "LI"){
      element = element.parentElement;
    }

    element.classList.add("activeQuota");

    this.validate();
    
  }

  nextStep(){
    this.step += 1;
  }

  stepBack(){
    this.step -= 1;
  }

  pay(){

    this.isLoading = true;
    
    this.socioService.payQuota(this.payMethod, this.nrMonthsSelected, this.mbwNR).subscribe((res:any)=>{

      if(res.status){
        
        if(this.payMethod == 'mb'){         
          this.entity = res.entity;
        }
        this.value = res.value;
        this.ref = res.ref;
        this.maxDate = res.maxDate;

        this.step = 0;
        this.isFinalStep = true;
        this.isLoading = false;

      }else{

        this.notifierService.notify("error","Não foi possível processar o seu pedido. Se o problema persistir contact o administrador do site.");

      }

    });

  }

  selectPayMethod(event, paymethod){
    this.payMethod = paymethod;

    let elements = document.getElementsByClassName("activePayMethod");
    for(let i = 0; i < elements.length; i++){
      elements[i].classList.remove("activePayMethod");
    }
    
    
    let element = event.target;
    while(element.nodeName != "LI"){
      element = element.parentElement;
    }

    element.classList.add("activePayMethod");
    
    this.validate();

  }

  validate(){
    this.isValid = true;

    if( !this.payTimeOption.includes(this.nrMonthsSelected) ){
      this.isValid = false;
      return;
    }
    
    if(this.payMethod != 'mb' && this.payMethod != 'mbw'){
      this.isValid = false;
      return;
    }

    if(this.payMethod == 'mbw'){
      let availablePrefix = ["91", "92", "93", "96"];
      if(!this.mbwNR || this.mbwNR.length != 9 || !availablePrefix.includes(this.mbwNR[0]+this.mbwNR[1])){
        this.isValid = false;
        //toast erro
        return;
      }
    }
  }

}
