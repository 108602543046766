import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {

  httpOptions = {
    headers: new HttpHeaders({
       'Content-Type': 'application/json; charset=UTF-8'
    })
  };
  

  private loginURL = environment.apiEndpoint + "/admin";

  constructor(private http: HttpClient, private sessionService : SessionService) { }

  public getSocios(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.loginURL+"/socios", httpOptionsAuthenticated).pipe();
  }

  public getSocio(nrSocio){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.loginURL+"/socios/"+nrSocio, httpOptionsAuthenticated).pipe();
  }


  public getDonations(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.loginURL+"/donations", httpOptionsAuthenticated).pipe();
  }

  public getLeads(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.loginURL+"/leads", httpOptionsAuthenticated).pipe();
  }

  public getTickets(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.loginURL+"/ticket", httpOptionsAuthenticated).pipe();
  }

  public getEvents(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.loginURL+"/events", httpOptionsAuthenticated).pipe();
  }

  public generateInvite(uuid, name){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.post(this.loginURL+"/ticket/gift", {uuid, name}, httpOptionsAuthenticated).pipe();
  }

}
