import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs'; 
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontEndService {

  httpOptions = {
    headers: new HttpHeaders({
       'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  private newsURL = environment.apiEndpoint + "/news";
  private highlights = environment.apiEndpoint + "/highlights";

  constructor(private http: HttpClient) { }

  public getNews() {
    return this.http.get(this.newsURL, this.httpOptions).pipe(
      catchError(this.handleError('getNews()'))
    );
  } 

  public getSingleNews(id:string) {
    return this.http.get(this.newsURL+'/'+id, this.httpOptions).pipe(
      catchError(this.handleError('getSingleNews()'))
    );
  } 

  public getHighlights() {
    return this.http.get(this.highlights, this.httpOptions).pipe(
      catchError(this.handleError('getHighlights()'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (!environment.production) {
        console.error(error); // log to console instead
      }
      return of(result as T);
    };
  }
}
