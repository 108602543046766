import { Component, OnInit } from '@angular/core';
import { BackOfficeService } from '../services/back-office.service';

@Component({
  selector: 'app-admin-noticias',
  templateUrl: './admin-noticias.component.html',
  styleUrls: ['./admin-noticias.component.css']
})
export class AdminNoticiasComponent implements OnInit {

  constructor(private backOfficeService : BackOfficeService) { }

  isLoading : boolean = true;

  newsLst : [];
  isEdit : boolean = false;
  isNew : boolean = false;
  newsObject : any;

  ngOnInit() {

    this.backOfficeService.getNews().subscribe((res:any)=>{
      this.newsLst = res;
      this.isLoading = false;
    });

  }

  handleEditNews(uuid:string){
    this.newsLst.forEach((news:any)=>{
      if(uuid == news.uuid){
        this.newsObject = news;
        this.isEdit = true;
      }
    });
  }

  handleNewNews(){
    this.isNew = true;
    this.newsObject = {
      "uuid": "",
      "title": "",
      "short_description": "",
      "body": "",
      "image": ""
    };
  }

  handleNewsChange(){
    this.isLoading = true;
    this.ngOnInit();
  }
}
