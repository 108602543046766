import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class SociosService {

  httpOptions = {
    headers: new HttpHeaders({
       'Content-Type': 'application/json; charset=UTF-8'
    })
  };
  

  private loginURL = environment.apiEndpoint + "/signin";
  private quotaURL = environment.apiEndpoint + "/quota";
  private socioURL = environment.apiEndpoint + "/socio";
  private partnerURL = environment.apiEndpoint + "/partner";

  constructor(private http: HttpClient, private sessionService : SessionService) { }

  public login(nif : String, email : String) {
    return this.http.post(this.loginURL, {email, nif},this.httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public getLastPaidQuota(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.quotaURL+"/last", httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public payQuota(method, months, phone){
    let data = {method, months, phone};
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.post(this.quotaURL+"/pay", data, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public checkPendingQuotas(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.quotaURL+"/pending", httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public getSocioCard(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.socioURL+"/card", httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public getSocioCardPDF(){
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.socioURL+"/card/pdf", httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public validateSocioCard(uuid:any){
    let httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8'
      })
    };
    return this.http.get(this.socioURL+"/card/validate/"+uuid, httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }
  
  public getOneByNIFNRPartner(nifOrNumber:any){
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-access-token': this.sessionService.getToken()
     })
    };
    return this.http.get(this.partnerURL+"/socio/find/"+nifOrNumber, httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (!environment.production) {
        console.error(error); // log to console instead
      }
      return of(result as T);
    };
  }
}
