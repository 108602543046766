import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DonationsService {

  constructor(private http: HttpClient) { }

  private donationURL = environment.apiEndpoint + "/donation";

  public payDonation(name, nif, email, value, method, phone){
    let data = {name, nif, email, value, method, phone};

console.log(data);

    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
      })
    };
    return this.http.post(this.donationURL, data, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }
}
