import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-admin-panel-donativos',
  templateUrl: './admin-panel-donativos.component.html',
  styleUrls: ['./admin-panel-donativos.component.css']
})
export class AdminPanelDonativosComponent implements OnInit {

  donations : any;

  constructor(private adminService : AdminServiceService) { }

  ngOnInit(): void {

    this.adminService.getDonations().subscribe((result)=>{
      this.donations = result;
    });

  }

}
