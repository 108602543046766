import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-admin-panel-leads',
  templateUrl: './admin-panel-leads.component.html',
  styleUrls: ['./admin-panel-leads.component.css']
})
export class AdminPanelLeadsComponent implements OnInit {

  leads : any;

  constructor(private adminService : AdminServiceService) { }

  ngOnInit(): void {

    this.adminService.getLeads().subscribe((result)=>{
      this.leads = result;
    });

  }

}
