<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>

<!-- page title -->
<section class="page-title-section overlay">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item">
            <a class="h2 text-primary font-secondary" href="@@page-link"
              >Bilhetes</a
            >
          </li>
          <li
            class="list-inline-item text-white h3 font-secondary @@nasted"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- /page title -->

<section class="section pt-3" *ngIf="step == 0" style="min-height: 497px">
  <div class="container">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let event of eventsMap | keyvalue">
        <a
          class="nav-link"
          [class.active]="event.key === selected"
          (click)="setActive($event)"
          >{{ event.key }}</a
        >
      </li>
    </ul>

    <div *ngFor="let event of eventsMap | keyvalue">
      <div *ngIf="event.key === selected">
        <div *ngIf="selected === 'Futsal'">
          <h4 class="mt-5 mb-2">Bilhetes individuais</h4>
          <div
            class="d-flex align-items-center justify-content-between mt-3 minwidth"
            *ngFor="let event of event.value"
          >
            <div class="flex-column">
              <span class="text-small text-muted">{{ event.title }}</span>
              <br />
              <span *ngIf="event.start_date != '3000-01-01 00:00:00'">
                <strong>{{ event.start_date | date : "EEE d MMM" }}</strong
                >&nbsp;{{ event.start_date | date : "HH" }}H{{
                  event.start_date | date : "mm"
                }}
                <br />
              </span>
              <span *ngIf="event.start_date == '3000-01-01 00:00:00'">
                <strong>Data e Hora a definir</strong> <br />
              </span>
              {{ event.venue_venue.name }} <br />
              <span *ngIf="!isSocio">Preço: <strong>{{ event.price | currency : "EUR" }}</strong></span>
            </div>
            <div class="flex-column">
              <span class="hide-text"
                >{{ event.team_a_team.name }}&nbsp;&nbsp;&nbsp;</span
              ><img src="{{ event.team_a_team.logo }}" width="50px" />
            </div>
            <div class="flex-column">
              <span class="versus">vs</span>
            </div>
            <div class="flex-column">
              <img src="{{ event.team_b_team.logo }}" width="50px" /><span
                class="hide-text"
                >&nbsp;&nbsp;&nbsp;{{ event.team_b_team.name }}</span
              >
            </div>
            <div class="flex-column">
              <button
                *ngIf="!isSocio"
                class="btn btn-primary float-right"
                (click)="buyTicket(event.uuid)"
              >
                Comprar Bilhete
              </button>
              <button
                *ngIf="isSocio"
                class="btn btn-primary float-right"
                (click)="buyTicket(event.uuid)"
              >
                Obter Bilhete
              </button>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="selected === 'Teatro'">
          <div
            class="d-flex justify-content-center mt-5 text-muted text-body-secondary"
          >
            <h5>Não existem eventos disponíveis.</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="placeHolder" *ngIf="showPlaceHolder">
      <div
        class="d-flex justify-content-center mt-5 text-muted text-body-secondary"
      >
        <h4>Não existem eventos disponíveis.</h4>
      </div>
    </div>
  </div>
</section>

<section
  class="section"
  *ngIf="step == 1"
  style="min-height: 497px; padding-top: 0px"
>
  <div class="container">
    <div
      class="d-flex align-items-center justify-content-between mt-5 mb-3 minwidth"
    >
      <div class="flex-column">
        <span class="text-small text-muted">{{ selectedEvent.title }}</span>
        <br />
        <strong>{{ selectedEvent.start_date | date : "EEE d MMM" }}</strong
        >&nbsp;{{ selectedEvent.start_date | date : "HH" }}H{{
          selectedEvent.start_date | date : "mm"
        }}
        <br />
        {{ selectedEvent.venue_venue.name }} <br />
        <span *ngIf="!isSocio">Preço: <strong>{{ selectedEvent.price | currency : "EUR" }}</strong></span>
      </div>
      <div class="flex-column">
        <span class="hide-text"
          >{{ selectedEvent.team_a_team.name }}&nbsp;&nbsp;&nbsp;</span
        ><img src="{{ selectedEvent.team_a_team.logo }}" width="50px" />
      </div>
      <div class="flex-column">
        <span class="versus">vs</span>
      </div>
      <div class="flex-column">
        <img src="{{ selectedEvent.team_b_team.logo }}" width="50px" /><span
          class="hide-text"
          >&nbsp;&nbsp;&nbsp;{{ selectedEvent.team_b_team.name }}</span
        >
      </div>
    </div>

    <p class="small mt-3">passo {{ step }} de {{ maxsteps }}</p>
    <h5>Preencha com os seus dados</h5>

    <ul class="list-group">
      <div class="row">
        <div class="col-xl-6">
          <div class="input-group w-100 mt-3 has-validation">
            <input
              id="name"
              type="text"
              class="form-control"
              placeholder="Nome"
              name="name"
              [(ngModel)]="name"
              (blur)="validateName()"
            />
            <div class="invalid-feedback">Por favor indique o seu nome</div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="input-group w-100 mt-3 has-validation">
            <input
              id="email"
              type="text"
              class="form-control"
              placeholder="Email para recepção do bilhete"
              name="email"
              [(ngModel)]="email"
              (blur)="validateEmail()"
            />
            <div class="invalid-feedback">
              Por favor indique um email válido
            </div>
          </div>
        </div>
        <div class="col-xl-6 mt-3">
          <div class="input-group w-100 has-validation">
            <input
              id="nic"
              type="text"
              class="form-control"
              placeholder="Cartão Cidadão"
              name="nic"
              [(ngModel)]="nic"
              (blur)="validateNIC()"
              required
            />
            <div class="invalid-feedback">
              {{ nicInvalidFeed }}
            </div>
          </div>
        </div>
        <div class="col-xl-6 mt-3">
          <div class="input-group w-100 has-validation">
            <input
              id="nif"
              type="text"
              class="form-control"
              placeholder="NIF"
              name="nif"
              [(ngModel)]="nif"
              (blur)="validateFormNIF()"
              required
            />
            <div class="invalid-feedback">
              {{ nifInvalidFeed }}
            </div>
          </div>
        </div>
        <div class="col-xl-6 mt-3">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input
              type="checkbox"
              class="custom-control-input"
              id="guest"
              [(ngModel)]="guest"
              name="guest"
            />
            <label class="custom-control-label guest" for="guest"
              >Visitante</label
            >
          </div>
        </div>
        <div class="col-xl-6 mt-3">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input
              type="checkbox"
              class="custom-control-input"
              id="mobility"
              [(ngModel)]="mobility"
              name="mobility"
            />
            <label class="custom-control-label mobility" for="mobility"
              >Mobilidade Condicionada</label
            >
          </div>
        </div>
      </div>
    </ul>

    <div class="row mt-3">
      <div class="col-md-4">
        <button
          class="btn btn btn-outline-warning w-100 mb-1"
          (click)="stepBack()"
        >
          Voltar
        </button>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <button
          class="btn btn-primary w-100"
          (click)="validateFormData()"
          [disabled]="
            !(nifIsValid && nicIsValid && nomeIsValid && emailIsValid)
          "
        >
          Avançar
        </button>
      </div>
    </div>
  </div>
</section>

<section
  class="section"
  *ngIf="step == 2"
  style="min-height: 497px; padding-top: 0px"
>
  <div class="container mt-3 quotas">
    <div
      class="d-flex align-items-center justify-content-between mt-5 mb-3 minwidth"
    >
      <div class="flex-column">
        <span class="text-small text-muted">{{ selectedEvent.title }}</span>
        <br />
        <strong>{{ selectedEvent.start_date | date : "EEE d MMM" }}</strong
        >&nbsp;{{ selectedEvent.start_date | date : "HH" }}H{{
          selectedEvent.start_date | date : "mm"
        }}
        <br />
        {{ selectedEvent.venue_venue.name }} <br />
        <span *ngIf="!isSocio">Preço: <strong>{{ selectedEvent.price | currency : "EUR" }}</strong></span>
      </div>
      <div class="flex-column">
        <span class="hide-text"
          >{{ selectedEvent.team_a_team.name }}&nbsp;&nbsp;&nbsp;</span
        ><img src="{{ selectedEvent.team_a_team.logo }}" width="50px" />
      </div>
      <div class="flex-column">
        <span class="versus">vs</span>
      </div>
      <div class="flex-column">
        <img src="{{ selectedEvent.team_b_team.logo }}" width="50px" /><span
          class="hide-text"
          >&nbsp;&nbsp;&nbsp;{{ selectedEvent.team_b_team.name }}</span
        >
      </div>
    </div>
    <p class="small">passo {{ step }} de {{ maxsteps }}</p>
    <h5>Escolhe a forma de pagamento</h5>
    <ul class="list-group">
      <li
        class="list-group-item"
        (click)="selectPayMethod($event, 'MBW')"
        [class.activeQuota]="payMethod == 'MBW'"
      >
        <span
          ><img
            src="../../../assets/img/mbway_logo.png"
            width="50px"
            alt=""
          /><strong class="ml-3">MB WAY</strong
          ><span *ngIf="payMethod == 'MBW'" class="small"
            >&nbsp;&nbsp;&nbsp; Para finalizar introduza o número de telemóvel
            associado ao MB WAY.</span
          ></span
        >
        <div class="input-group w-100" *ngIf="payMethod == 'MBW'">
          <div class="input-group-prepend">
            <span class="input-group-text" id=""
              ><img
                src="../../../assets/img/flag_pt.png"
                width="25px"
                alt=""
              />&nbsp;+351</span
            >
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Telemóvel"
            name="mbwNR"
            [(ngModel)]="mbwNR"
            (keyup)="validatePaymentOptions()"
          />
        </div>
      </li>
      <br />
    </ul>
    <div class="row">
      <div class="col-md-4">
        <button
          class="btn btn btn-outline-warning w-100 mb-1"
          (click)="stepBack()"
        >
          Voltar
        </button>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <button
          class="btn btn-primary w-100"
          (click)="pay()"
          [disabled]="!isValidPayOptions"
        >
          Confirmar
        </button>
      </div>
    </div>
    <div class="clearfix mb-5"></div>
  </div>
</section>

<div class="container mt-3 ticket_final_step" *ngIf="isFinalStep">
  <h3 class="text-left mt-3 mb-0 p-0">Compra de bilhete</h3>
  <br class="print-no_display" />
  <div
    class="d-flex align-items-center justify-content-between minwidth text-left"
  >
    <div class="flex-column">
      <span class="text-small text-muted">{{ selectedEvent.title }}</span>
      <br />
      <strong>{{ selectedEvent.start_date | date : "EEE d MMM" }}</strong
      >&nbsp;{{ selectedEvent.start_date | date : "HH" }}H{{
        selectedEvent.start_date | date : "mm"
      }}
      <br />
      {{ selectedEvent.venue_venue.name }} <br />
      <span *ngIf="!isSocio">Preço: <strong>{{ selectedEvent.price | currency : "EUR" }}</strong></span>
    </div>
    <div class="flex-column">
      <span class="hide-text"
        >{{ selectedEvent.team_a_team.name }}&nbsp;&nbsp;&nbsp;</span
      ><img src="{{ selectedEvent.team_a_team.logo }}" width="50px" />
    </div>
    <div class="flex-column">
      <span class="versus">vs</span>
    </div>
    <div class="flex-column">
      <img src="{{ selectedEvent.team_b_team.logo }}" width="50px" /><span
        class="hide-text"
        >&nbsp;&nbsp;&nbsp;{{ selectedEvent.team_b_team.name }}</span
      >
    </div>
  </div>
  <div *ngIf="!isSocio">
    <p class="alert mt-3" *ngIf="payMethod == 'MB'">
      Os dados abaixo foram enviados para o e-mail indicado e não estarão
      disponíveis para consulta mais tarde nesta página! Caso pretenda guardar
      estes dados imprima a página ou consulte o seu e-mail.
    </p>

    <div *ngIf="payMethod == 'MB'" class="mt-3">
      <div class="print-text">
        <img
          src="../../assets/img/mb_logo.png"
          width="100px"
          alt=""
          class="print-img"
        /><br />
        <table style="margin-left: auto; margin-right: auto">
          <tr>
            <td class="font-weight-bold">Entidade:</td>
            <td>{{ entity }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Referência:</td>
            <td>{{ ref }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Valor:</td>
            <td>{{ value | currency : "EUR" }}</td>
          </tr>
        </table>

        <span class="small">Referência válida até {{ maxDate | date }} </span
        ><br /><br />

        <p>Quando confirmado o pagamento receberá o bilhete no seu e-mail.</p>
        <button class="btn btn-info" onclick="window.print();return false;">
          Imprimir&nbsp;&nbsp;&nbsp;<i class="ti-printer"></i>
        </button>
        <div style="min-height: 75px"></div>
      </div>
    </div>
    <div *ngIf="payMethod == 'MBW'" class="mt-1">
      <div style="min-height: 75px"></div>
      <img
        src="../../assets/img/mbway_logo.png"
        width="100px"
        alt=""
        class="print-img"
      /><br />
      <p>
        Foi enviado para o telemóvel <strong>{{ mbwNR }}</strong> o pedido de
        pagamento.
      </p>
      <p class="small">
        Dispõe de 4 minutos para efetuar o pagamento. <br />Receberá o bilhete
        no email indicado após confirmação do pagamento.
      </p>
      <div style="min-height: 43px"></div>
    </div>
  </div>
  <div *ngIf="isSocio">
    <p class="alert mt-3" style="min-height: 305px;">
      O bilhete foi enviado para o e-mail indicado. <br />
      Obrigado pelo seu apoio!
    </p>
  </div>
  <div class="clearfix mb-5"></div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="InfoBilheteEpoca"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content rounded-0 border-0 p-4">
      <div class="modal-header border-0">
        <h3>Bilhete de Época</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closebutton
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="mb-3">CONDIÇÕES DE AQUISIÇÃO DO BILHETE DE ÉPOCA</h4>

        <p class="ml-4">
          <strong>Sócios</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;Preço:
          {{ SEASON_TICKET_PRICE | currency : "EUR" }} <br />
          &nbsp;&nbsp;&nbsp;&nbsp;Quotas pagas até, pelo menos, Abril de 2024
          <br /><br />

          <strong>Não Sócios</strong> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;Deverá realizar a sua inscrição como sócio -
          clique <a routerLink="/socio/novo" data-dismiss="modal">aqui</a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;Preço:
          {{ SEASON_TICKET_PRICE | currency : "EUR" }}
        </p>

        <h5 class="mt-3">Informações Gerais</h5>
        <ol class="lista">
          <li>
            O Bilhete de Época permite o acesso a todos os jogos em casa da ASC
            Vale d'Ouro na fase regular e na fase de play-off do Campeonato
            Distrital de Futsal Masculino (mínimo 9 jogos) bem como em todos os
            jogos em casa da Taça Distrital de Futsal Masculino.
          </li>
          <li>
            O acesso ao pavilhão é realizado pela validação eletrónica do código
            de barras inscrito no bilhete eletrónica (pdf) que vai receber no
            seu e-mail.
          </li>
          <li>O bilhete poderá ser apresentado em papel ou no telemóvel.</li>
          <li>
            Poderá ser solicitado, para acesso ao pavilhão, o Cartão do Cidadão.
          </li>
          <li>
            O Bilhete de Época é intransmissível. Apenas o seu titular o poderá
            utilizar. Não é permitida a cedência a terceiros, sócios ou não
            sócios.
          </li>
          <li>
            O titular do Bilhete de Época deve cumprir as Regras de Permanência
            no Pavilhão.
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="BilheteEpocaModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content rounded-0 border-0 p-4">
      <div class="modal-header border-0">
        <h3>O Bilhete de Época apenas se encontra disponível para sócios.</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closebutton
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xl-6">
            <button
              class="btn btn-primary w-100"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#loginSociosModal"
            >
              Já sou sócio
            </button>
          </div>
          <div class="col-xl-6">
            <button
              class="btn btn-primary w-100"
              data-dismiss="modal"
              routerLink="/socio/novo"
            >
              Quero ser sócio
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>