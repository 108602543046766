import { Component, OnInit } from '@angular/core';
import { FrontEndService } from '../services/front-end.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {

  isLoading : boolean = true;
  newsList : [] = [];

  constructor(private frontEndServices : FrontEndService) { }

  ngOnInit() {

    this.frontEndServices.getNews().subscribe((res:any)=>{
      this.newsList = res;
      setTimeout(() => {
        this.isLoading = false;
      }, 250);
    });

  }

}
