import { AfterViewInit, Component, OnInit, ViewChildren } from "@angular/core";
import { FrontEndService } from "../services/front-end.service";

declare var $: any;

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.css"],
})
export class SliderComponent implements OnInit, AfterViewInit {

  isLoading : boolean = true;

  @ViewChildren('highlightsDOM') highlightsDOM: any ;
  highlightLst: [];

  constructor(private frontEndService: FrontEndService) {}
  
  ngAfterViewInit(): void {
    this.highlightsDOM.changes.subscribe(t => {
      $(".hero-slider").slick({
        autoplay: true,
        autoplaySpeed: 7500,
        pauseOnFocus: false,
        pauseOnHover: false,
        infinite: true,
        arrows: true,
        fade: true,
        prevArrow:
          "<button type='button' class='prevArrow'><i class='ti-angle-left'></i></button>",
        nextArrow:
          "<button type='button' class='nextArrow'><i class='ti-angle-right'></i></button>",
        dots: true,
      });
      $(".hero-slider").slickAnimation();
      this.isLoading = false;
    })
  }

  ngOnInit() {
    this.frontEndService.getHighlights().subscribe((res: any) => {
      this.highlightLst = res;     
    });
  }
}
