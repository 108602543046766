<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <ul class="list-inline custom-breadcrumb">
            <li class="list-inline-item"><a class="h2 text-primary font-secondary" href="@@page-link">Órgãos Sociais</a></li>
            <li class="list-inline-item text-white h3 font-secondary @@nasted"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- /page title -->
  
  <!-- teachers -->
  <section class="section">
    <div class="container">
      <!--div class="row">
        <div class="col-12">
          <ul class="list-inline text-center filter-controls mb-5">
            <li class="list-inline-item m-3 text-uppercase active" data-filter="all">Todos</li>
            <li class="list-inline-item m-3 text-uppercase" data-filter="direcao">Direção</li>
            <li class="list-inline-item m-3 text-uppercase" data-filter="mag">Mesa da Assembleia Geral</li>
            <li class="list-inline-item m-3 text-uppercase" data-filter="conselhoFiscal">Conselho Fiscal</li>
            <li class="list-inline-item m-3 text-uppercase" data-filter="suplente">Suplentes</li>
          </ul>
        </div>
      </div-->
      <!-- teacher list -->
      <div class="row"> <!-- filtr-container -->
        <!-- teacher -->
        <div data-category="direcao" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/luis.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Luís Almeida</h4>
              </a>
              <p>Presidente da Direção</p>

            </div>
          </div>
        </div> 

        <div data-category="direcao" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="https://media.ascvd.pt/fotos/pessoas/katiarocha.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Kátia Rocha</h4>
              </a>
              <p>Vice-Presidente da Direção</p>

            </div>
          </div>
        </div> 

        <div data-category="direcao" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"   src="https://media.ascvd.pt/fotos/pessoas/fernandaSousa.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Fernanda Sousa</h4>
              </a>
              <p>Secretária da Direção</p>

            </div>
          </div>
        </div> 

        <div data-category="direcao" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="https://media.ascvd.pt/fotos/pessoas/claudioPereira.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Cláudio Pereira</h4>
              </a>
              <p>Vogal da Direção</p>

            </div>
          </div>
        </div> 
        
        <div data-category="direcao" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"   src="../../assets/img/orgaos/ana_g.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Ana Gouveia</h4>
              </a>
              <p>Vogal da Direção</p>

            </div>
          </div>
        </div> 

        <div data-category="mag" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="https://media.ascvd.pt/fotos/pessoas/lilamartins.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Liliana Martins</h4>
              </a>
              <p>Presidente da Mesa da Assembleia Geral</p>

            </div>
          </div>
        </div> 

        <div data-category="mag" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="https://media.ascvd.pt/fotos/pessoas/marlenepimentel.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Marlente Pimentel</h4>
              </a>
              <p>Vice-Presidente da Mesa da Assembleia Geral</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="mag" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/rafael.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">José Almeida</h4>
              </a>
              <p>Secretário da Mesa da Assembleia Geral</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="conselhoFiscal" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="https://media.ascvd.pt/fotos/pessoas/ruibarata.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Rui Barata</h4>
              </a>
              <p>Presidente do Conselho Fiscal</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="conselhoFiscal" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="https://media.ascvd.pt/fotos/pessoas/pedrosousa.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Pedro Sousa</h4>
              </a>
              <p>Vice-Presidente do Conselho Fiscal</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="conselhoFiscal" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="https://media.ascvd.pt/fotos/pessoas/pedropirez.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Pedro Pires</h4>
              </a>
              <p>Secretário do Conselho Fiscal</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Inês Pires</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Patrícia Soares</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 
        
        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Tânia Cardoso</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Patrícia da Rocha</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">João Silva</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Abel Sousa</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Luís Filipe Brás</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Maria Fernandes</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Clara Loureiro</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Inês Antunes</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

        <div data-category="suplente" class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../assets/img/orgaos/holder.jpg" >
            <div class="card-body">
              <a href="teacher-single.html">
                <h4 class="card-title">Jorge Simões</h4>
              </a>
              <p>Suplente</p>
              <!--ul class="list-inline">
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-facebook"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-twitter-alt"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-google"></i></a></li>
                <li class="list-inline-item"><a class="text-color" href="#"><i class="ti-linkedin"></i></a></li>
              </ul-->
            </div>
          </div>
        </div> 

      </div>
    </div>
  </section>
  <!-- /teachers -->
  <app-footer></app-footer>