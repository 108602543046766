<section class="section bg-cover" style="background-image: url('../../assets/img/bg_video.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-4 position-relative success-video">
        <a class="play-btn venobox vbox-item" href="https://youtu.be/rFz9r66Svr8" data-vbtype="video">
          <i class="ti-control-play"></i>
        </a>
      </div>
      <div class="col-lg-6 col-sm-8">
        <div class="bg-white p-5">
          <h2 class="section-title">MENSAGEM 15.º Aniversário</h2>
          <p>Mensagem da Presidente da Mesa da Assembleia-Geral, Liliana Martins, por ocasião do 15.º aniversário da Associação Vale d'Ouro em 24 de junho de 2023</p>

        </div>
      </div>
    </div>
  </div>
</section>