import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from 'src/app/services/admin-service.service';
import {saveAs as importedSaveAs} from "file-saver";

@Component({
  selector: 'app-admin-panel-eventos',
  templateUrl: './admin-panel-eventos.component.html',
  styleUrls: ['./admin-panel-eventos.component.css']
})
export class AdminPanelEventosComponent implements OnInit {

  events : any;
  name : string;

  selectedEvent : any;

  pendingAction = false;

  constructor(private adminService : AdminServiceService) { }

  ngOnInit(): void {

    this.adminService.getEvents().subscribe((result : any)=>{
      let today = new Date();
      result.forEach(event => {
        event.canInvite = event.end_date == null && new Date(event.start_date) >= today;
      });
      this.events = result;
    });

  }

  selectEvent(event : any){
    this.selectedEvent = event;
  }

  generateInvite(){

    this.pendingAction = true;

    this.adminService.generateInvite(this.selectedEvent.uuid, this.name).subscribe((result:any)=>{

      this.pendingAction = false;

      const byteArray = new Uint8Array(atob(result.data).split('').map(char => char.charCodeAt(0)));
      var data = new Blob([byteArray], { type: 'application/pdf' });
      importedSaveAs(data, this.name+".pdf");

      this.name = '';

    });
  }

}
