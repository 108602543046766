<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item"><a class="h2 text-primary font-secondary">Quotas</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container mt-3 quotas" *ngIf="step == 99">
  <p>TEM {{pendingQuotas.length}} PAGAMENTO<span *ngIf="pendingQuotas.length > 1">S</span>&nbsp;DE QUOTA PENDENTE<span *ngIf="pendingQuotas.length > 1">S</span></p>
  <div class="row text-center">
    <div class="col-md-3" *ngFor="let quota of pendingQuotas" style="cursor: default;">
      <div *ngIf="quota.metodo == 'MB'">
        <img src="../../../assets/img/mb_logo.png" width="100px" alt=""> <br/>
        <strong>Entidade:</strong> {{quota.entidade}} <br />
        <strong>Referência:</strong> {{quota.referencia}} <br />
        <strong>Valor:</strong> {{quota.valor | currency:'EUR' }}<br />
      </div>
      <div *ngIf="quota.metodo == 'MBW'">
        <img src="../../../assets/img/mbway_logo.png" width="100px" alt=""> <br/>
        <p class="mb-0">Pagamento pendente com <strong>Referência:</strong> {{quota.referencia}}</p>
        <strong>Valor:</strong> {{quota.valor | currency:'EUR' }}<br />
      </div>
    </div>
  </div>
</div>
<div class="container mt-3 quotas" *ngIf="step == 1">
    <p>POR REGULARIZAR + PRÓXIMAS QUOTAS</p>
    <p class="small">passo {{step}} de {{maxsteps}}</p>
    <ul class="list-group">
        <div *ngFor="let option of payOptions">
            <li class="list-group-item" (click)="activate($event, option.months)" [class.activeQuota]="option.months == nrMonthsSelected">
                <span><strong>+{{option.months}} meses</strong></span>
                <div class="float-right">
                    <span class="small">até {{option.until | date:"MMM yyyy"}} &nbsp;</span>
                    <span class="text-bold"><strong>{{option.totalPrice}} €</strong></span>
                </div>            
            </li><br />
        </div>
      </ul>
      <button class="btn btn-primary float-right w-25 mb-5" (click)="nextStep()" [disabled]="nrMonthsSelected == undefined">Seguinte</button>
      <div class="clearfix mb-5"></div>
</div>
<div class="container mt-3 quotas" *ngIf="step == 2">
  <p>Escolhe a forma de pagamento</p>
  <p class="small">passo {{step}} de {{maxsteps}}</p>
  <ul class="list-group">
    <li class="list-group-item" (click)="selectPayMethod($event, 'mb')"  [class.activeQuota]="payMethod == 'mb'">
        <span><img src="../../../assets/img/mb_logo.png" width="50px" alt=""><strong class="ml-3">Referência Multibanco</strong><span *ngIf="payMethod == 'mb'" class="small">&nbsp;&nbsp;&nbsp; Os dados de pagamento ficam disponíveis na confirmação da encomenda.</span></span>
                  
    </li><br />
    <li class="list-group-item" (click)="selectPayMethod($event, 'mbw')" [class.activeQuota]="payMethod == 'mbw'">
      <span><img src="../../../assets/img/mbway_logo.png" width="50px" alt=""><strong class="ml-3">MB WAY</strong><span *ngIf="payMethod == 'mbw'" class="small">&nbsp;&nbsp;&nbsp; Para finalizar introduza o número de telemóvel associado ao MB WAY.</span></span>
      <div class="input-group w-100" *ngIf="payMethod == 'mbw'">
        <div class="input-group-prepend">
          <span class="input-group-text" id=""><img src="../../../assets/img/flag_pt.png" width="25px" alt="">&nbsp;+351</span>
        </div>
        <input type="text" class="form-control" placeholder="Telemóvel" name="mbwNR" [(ngModel)]="mbwNR" (keyup)="validate()">
      </div>            
  </li><br />
  </ul>
  <div class="row">
    <div class="col-md-4"><button class="btn btn btn-outline-warning w-100 mb-1" (click)="stepBack()">Voltar</button></div>
    <div class="col-md-4"></div>
    <div class="col-md-4"><button class="btn btn-primary w-100" (click)="pay()" [disabled]="!isValid">Confirmar</button></div>
  </div>
  <div class="clearfix mb-5"></div>
</div>

<div class="container mt-3 text-center final-step" *ngIf="isFinalStep">
  <div *ngIf="payMethod == 'mb'" class="mt-3">
    <h6 class="print-h1">Obrigado por continuar a apoiar a região!</h6>
    <div class="print-text">
      <img src="../../assets/img/mb_logo.png" width="100px" alt="" class="print-img" /><br />
      <table style="margin-left: auto; margin-right: auto">
        <tr>
          <td class="font-weight-bold">Entidade:</td>
          <td>{{ entity }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Referência:</td>
          <td>{{ ref }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Valor:</td>
          <td>{{ value | currency : "EUR" }}</td>
        </tr>
      </table>

      <span class="small">Referência válida até {{ maxDate | date }} </span><br /><br />

      <p>
        Quando confirmado o pagamento receberá as restantes informações no seu
        e-mail.
      </p>
      <div style="min-height: 75px"></div>
    </div>
  </div>
  <div *ngIf="payMethod == 'mbw'" class="mt-3">
    <div style="min-height: 75px"></div>
    <img src="../../assets/img/mbway_logo.png" width="100px" alt="" class="print-img" /><br />
    <h6 class="print-h1">Obrigado pelo seu contributo</h6>
    <p>
      Foi enviado para o telemóvel <strong>{{ mbwNR }}</strong> o pedido de
      pagamento.
    </p>
    <p class="small">Dispõe de 4 minutos para efetuar o pagamento.</p>
    <div style="min-height: 210px"></div>
  </div>

  <div class="clearfix mb-5"></div>
</div>
<!-- /page title -->


<!-- /notice -->
<app-footer></app-footer>