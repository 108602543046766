import { Component, OnInit } from '@angular/core';
import { SociosService } from 'src/app/services/socios.service';
import {saveAs as importedSaveAs} from "file-saver";

@Component({
  selector: 'app-cartao',
  templateUrl: './cartao.component.html',
  styleUrls: ['./cartao.component.css']
})
export class CartaoComponent implements OnInit {

  isLoading : boolean = true;
  socioCardB64 : string = "";
  qrCodeB64 : string = "";
  pendingAction : boolean = false;

  constructor(private socioService : SociosService) { }

  ngOnInit(): void {
    this.socioService.getSocioCard().subscribe((result:any)=>{
      
      if(result.status && result.card){
        this.socioCardB64 = result.card;
        this.qrCodeB64 = result.qrCode;

      }

      this.isLoading = false;
    });
    
  }

  generateCardPDF(){

    this.pendingAction = true;

    this.socioService.getSocioCardPDF().subscribe((result:any)=>{

      this.pendingAction = false;

      const byteArray = new Uint8Array(atob(result.data).split('').map(char => char.charCodeAt(0)));
      var data = new Blob([byteArray], { type: 'application/pdf' });
      importedSaveAs(data, "cartao_socio_vale_dOuro.pdf");

    });
  }

}
