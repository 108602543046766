import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class BackOfficeService {

  

  httpOptions = {
    headers: new HttpHeaders({
       'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  private newsURL = environment.apiEndpoint + "/news";
  private highlightsURL = environment.apiEndpoint + "/highlights";
  private loginURL = environment.apiEndpoint + "/login";

  constructor(private http: HttpClient, private sessionService : SessionService) { 

  }

  public login(email : String, password : String) {
    return this.http.post(this.loginURL, {email, password},this.httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public createHighlight(data:any) {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.post(this.highlightsURL, data, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public updateHighlight(data:any) {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.put(this.highlightsURL, data, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public createNews(data:any) {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.post(this.newsURL, data, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public updateNews(data:any) {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.put(this.newsURL, data, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }


  public getNews() {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.newsURL, httpOptionsAuthenticated).pipe(
      catchError(this.handleError('getNews()'))
    );
  } 

  public getSingleNews(id:string) {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.newsURL+'/'+id, httpOptionsAuthenticated).pipe(
      catchError(this.handleError('getSingleNews()'))
    );
  } 

  public getHighlights() {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.highlightsURL+"/all", httpOptionsAuthenticated).pipe(
      catchError(this.handleError('getHighlights()'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (!environment.production) {
        console.error(error); // log to console instead
      }
      return of(result as T);
    };
  }
}
