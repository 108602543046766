<app-header></app-header>
<!-- page title -->
<section
  class="page-title-section overlay"
  data-background="images/backgrounds/page-title.jpg"
>
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item">
            <a class="h2 text-primary font-secondary"
              >MOSTRA DE TEATRO DO DOURO</a
            >
          </li>
          <li
            class="list-inline-item text-white h3 font-secondary @@nasted"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- /page title -->
<!-- success story -->
<section class="section bg-cover overlay-yt">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-4 position-relative success-video">
        <a
          class="play-btn venobox"
          href="https://youtu.be/dt_ZYAGdi7s?si=boE17wHiEnEYbhqi"
          data-vbtype="video"
        >
          <i class="ti-control-play"></i>
        </a>
      </div>
      <div class="col-lg-6 col-sm-8">
        <div class="bg-white p-5">
          <h2 class="section-title">XIV Mostra de Teatro do Douro</h2>
          <p>
            Em 2024 a Mostra de Teatro do Douro terá nove espetáculos
            distribuídos por sete concelhos e dois distritos. A comédia será o
            género exclusivos de uma programação em que a esmagadora maioria dos
            textos são originais. Desde os clássicos ao teatro de revista, da
            reflexão ao humor popular, as propostas para a edição deste ano são
            muito diversificadas.
          </p>
          <p>
            <a
              class="btn btn-primary mt-1"
              href="https://media.ascvd.pt/eventos/cartaz24_multi.jpg"
              target="_blank"
              >Ver cartaz</a
            >&nbsp;&nbsp;&nbsp;<a
              class="btn btn-primary mt-1"
              href="https://www.agendadouro.pt"
              target="_blank"
              >Ver Espetáculos</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- /success story -->

<!-- about -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="section-title">HISTÓRIA</h2>
        <p>
          A história da Mostra de Teatro do Douro começou em 2009 e desde aí o
          número de espectadores, de grupos de teatro e de espetáculos não pára
          de crescer.
        </p>
        <p>
          Este certame tem desempenhado um papel crucial no panorama cultural
          regional, através da sua passagem por diversos palcos e,
          principalmente, pela iniciativa de levar o teatro a zonas geográficas
          e a populações que se encontram limitadas a vários níveis no acesso a
          este tipo de atividades.
        </p>
        <p>
          Os principais objetivos do festival são diversificar a oferta cultural
          no Douro e criar uma rede de atividade teatral em que os grupos não
          profissionais da região norte possam apresentar o seu trabalho. O
          evento tem contribuído para a preservação do património cultural e
          etnográfico estimulando o aparecimento de peças com base nas vivências
          da região e de autores locais.
        </p>
        <p>
          A Mostra de Teatro do Douro é o expoente máximo de um fenómeno de rede
          cultural que informalmente existe na região.
        </p>
        <p>
          A magia do teatro, todos os anos, ao longo de quase dois meses, inunda
          o vale encantado unindo as margens de rio de emoções.
        </p>
      </div>
      <div class="col-12 mt-3">
        <h2 class="section-title">FORMATO</h2>
        <p>
          O formato da Mostra de Teatro do Douro procurar atender a três
          principais objetivos:
        </p>
        <ul class="mt-0">
          <li>
            a distribuição da produção cultural de grupos não profissionais da
            região Norte do país;
          </li>
          <li>
            o acesso dos grupos não profissionais aos principais palcos da
            região;
          </li>
          <li>
            levar o teatro a comunidades que habitualmente não tem oferta
            cultural muito expressiva ao longo do ano.
          </li>
        </ul>
        <p>
          Em cada edição, além dos principais palcos da região, são escolhidas
          localizações onde as atividades culturais ou a promoção do teatro não
          sejam tão regulares. Paralelamente é distribuída a produção cultural
          por alguns dos principais palcos do Vale do Douro.
        </p>
        <p>
          O evento conta com o apoio da Comissão de Coordenação e
          Desenvolvimento Regional do Norte, da Fundação Inatel, dos Municípios
          e Juntas de Freguesia aderentes e tem como media partner a Rádio
          Universidade FM.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- /about -->

<!-- funfacts -->
<section class="section-sm bg-primary">
  <div class="container">
    <div class="row">
      <!-- funfacts item -->
      <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
        <div class="text-center">
          <h2 class="count text-white" data-count="120">0</h2>
          <h5 class="text-white">Média de espectadores por espetáculo</h5>
        </div>
      </div>
      <!-- funfacts item -->
      <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
        <div class="text-center">
          <h2 class="count text-white" data-count="91">0</h2>
          <h5 class="text-white">Espetáculos</h5>
        </div>
      </div>
      <!-- funfacts item -->
      <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
        <div class="text-center">
          <h2 class="count text-white" data-count="106">0</h2>
          <h5 class="text-white">Horas teatrais</h5>
        </div>
      </div>
      <!-- funfacts item -->
      <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
        <div class="text-center">
          <h2 class="count text-white" data-count="18">0</h2>
          <h5 class="text-white">Companhias de teatro</h5>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- /funfacts -->
<!-- research -->
<section class="section">
  <div class="container">
    <h2 class="section-title">CARTAZES</h2>
    <div class="row">
      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2009.JPG"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2009</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2010.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2010</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2011.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2011</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2012.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2012</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2013.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2013</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2014.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2014</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2015.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2015</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2016.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2016</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2017.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2017</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2018.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2018</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2019.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2019</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2022.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2022</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2023.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2023</h4>
          </div>
        </div>
      </div>

      <!-- recharch item -->
      <div class="col-lg-4 col-sm-6 mb-4">
        <div
          class="card rounded-0 hover-shadow border-top-0 border-left-0 border-right-0"
        >
          <img
            class="card-img-top rounded-0"
            src="../../../assets/img/mtd_cartazes/mostra2024.jpg"
            alt="Cartaz"
          />
          <div class="card-body">
            <h4 class="card-title">2024</h4>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- /research -->

<section class="section">
    <div class="container">
    <h2 class="section-title">GRUPOS DE TEATRO</h2>
      <!-- teacher list -->
      <div class="row"> <!-- filtr-container -->
        <!-- teacher -->
        <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
          <div class="card border-0 rounded-0 hover-shadow">
            <img class="card-img-top rounded-0"  src="../../../assets/img/teatraco.jpg" >
            <div class="card-body">
              <a routerLink="./da0eb644-b2c1-4bbf-83b7-29766494d53e">
                <h4 class="card-title">TEATRAÇO – Teatro Amador de Tabuaço</h4>
              </a>
              <p>Tabuaço</p>
            </div>
          </div>
        </div> 

        <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/getepepe.png" >
              <div class="card-body">
                <a routerLink="./8eefbb2c-651c-4edb-a40d-239e3c65f388">
                  <h4 class="card-title">GETEPEPE - Teatro Perafita</h4>
                </a>
                <p>Perafita - Matosinhos</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/teatro_boticas.jpeg" >
              <div class="card-body">
                <a routerLink="./2b723919-170b-4f56-83c9-10e3a0c8343a">
                  <h4 class="card-title">Teatro Fórum Boticas</h4>
                </a>
                <p>Boticas</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/gt_lordelense.jpg" >
              <div class="card-body">
                <a routerLink="./ce2f4e69-e516-408a-b2b9-6836a7ca13b9">
                  <h4 class="card-title">Grupo de Teatro do Centro Cultural Lordelense</h4>
                </a>
                <p>Lordelo - Vila Real</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/ac_vermoim.jpg" >
              <div class="card-body">
                <a routerLink="./5145f529-a331-4c44-b937-b455af50d07d">
                  <h4 class="card-title">Núcleo de Teatro AC Vermoim</h4>
                </a>
                <p>Vermoim - Famalicão</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/gt_gtn.jpg" >
              <div class="card-body">
                <a routerLink="./a9f1d8ef-1ad8-4c90-828c-3c187f5dadad">
                  <h4 class="card-title">GTN - Trupe Fernando Leal</h4>
                </a>
                <p>Novelas - Penafiel</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/gtav.jpg" >
              <div class="card-body">
                <a routerLink="./7ef1b811-01fb-4cb3-b799-8548543f2ec3">
                  <h4 class="card-title">GTAV - Grupo de Teatro Aldeia Verde de Lazarim</h4>
                </a>
                <p>Lazarim - Lamego</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/tef.png" >
              <div class="card-body">
                <a routerLink="./ab0412f9-f21f-47ca-919a-dea412ca966e">
                  <h4 class="card-title">TEF - Teatro Experimental Flaviense</h4>
                </a>
                <p>Chaves</p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 mb-5 filtr-item">
            <div class="card border-0 rounded-0 hover-shadow">
              <img class="card-img-top rounded-0"  src="../../../assets/img/tav.jpg" >
              <div class="card-body">
                <a routerLink="./71af3361-35a2-42c3-a5ce-d484bae20360">
                  <h4 class="card-title">TAV - Teatro do Ave</h4>
                </a>
                <p>Vila do Conde</p>
              </div>
            </div>
          </div>


      </div>
    </div>
  </section>

<app-footer></app-footer>
