<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <ul class="list-inline custom-breadcrumb">
            <li class="list-inline-item"><a class="h2 text-primary font-secondary">Sobre nós</a></li>
            <li class="list-inline-item text-white h3 font-secondary @@nasted"></li>
          </ul>        </div>
      </div>
    </div>
  </section>
  <!-- /page title -->
  
  <!-- about -->
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <img class="img-fluid mb-4 w-100" src="../../assets/img/gala2.jpg" alt="about image">
          <h2 class="section-title">HISTÓRIA</h2>
          <p>A Associação Sociocultural Vale d’Ouro é uma entidade da economia social que atua na área cultural e social na região do Douro, Beira Alta e Trás-os-Montes com sede na vila do Pinhão, concelho de Alijó.
        </p>
        <p>Foi fundada em 24 de junho de 2008 e desde essa altura tem desenvolvido diversas atividades tais como organização de espetáculos e eventos culturais (teatro, música e dança), organização de conferencias, seminários e workshops, produção para rádio de diversos programas sobre a região, atividades desportivas. Mais recentemente tem-se destacada na defesa pela Linha do Douro e apresentou um estudo para a implementação de uma linha de Alta Velocidade entre o Porto e Madrid via Trás-os-Montes.
        </p>
        <p>Integra ainda na sua estrutura o Grupo de Teatro da Associação Vale d’Ouro (projeto cultural destinado sobretudo a camadas jovens da vila do Pinhão) e mais recentemente uma Academia de Futsal que proporciona contexto de formação até aos 16 anos e de competição nos escalões seniores. Até 2020 a Associação Vale d’Ouro desenvolvia o projeto da Academia de Artes Douro e Tâmega, interrompido pela pandemia covid-19. No passado, entretanto extinta, teve também uma secção autónoma que tinha como propósito a edição de um boletim informativo quinzenal na vila onde se encontra sedeada.
        </p>
        <p>Em 2019 foi, pela primeira vez, reconhecida a utilidade pública da instituição por despacho da Secretaria de Estado da Presidência do Conselho de Ministros. Um anos, em 2018, comemorou o seu décimo aniversário onde galardoou com diferentes categorias de sócio de mérito 72 entidades que incluíam sócios da instituição, entidades particulares e coletivas que deram, ao longo dos anos contributo relevante à instituição. Destas 72 distinções, 37 correspondem a novos associados coletivos e que representam, de um modo geral, a rede das principais instituições com que a Associação Vale d’Ouro se tem relacionado e com quem tem colaborado para executar a sua missão estatutária.
        </p>
        <p>O recente estudo sobre uma nova ligação ferroviária de alta velocidade entre Porto e Madrid, via Trás-os-Montes catapultou a instituição para um panorama nacional tendo sido reconhecida a capacidade técnica na área ferroviária que tem permitido a defesa acérrima de uma visão para a região norte, centrada na Linha do Douro.          
        </p>
        </div>
      </div>
    </div>
  </section>
  <!-- /about -->
  
  <!-- funfacts -->
  <section class="section-sm bg-primary">
    <div class="container">
      <div class="row">
        <!-- funfacts item -->
        <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
          <div class="text-center">
            <h2 class="count text-white" data-count="365">0</h2>
            <h5 class="text-white">ASSOCIADOS</h5>
          </div>
        </div>
        <!-- funfacts item -->
        <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
          <div class="text-center">
            <h2 class="count text-white" data-count="2">0</h2>
            <h5 class="text-white">GRUPOS AUTÓNOMOS</h5>
          </div>
        </div>
        <!-- funfacts item -->
        <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
          <div class="text-center">
            <h2 class="count text-white" data-count="65">0</h2>
            <h5 class="text-white">ATIVIDADES REALIZADAS</h5>
          </div>
        </div>
        <!-- funfacts item -->
        <div class="col-md-3 col-sm-6 mb-4 mb-md-0">
          <div class="text-center">
            <h2 class="count text-white" data-count="2008">0</h2>
            <h5 class="text-white">ANO DA FUNCAÇÃO</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /funfacts -->
  
  <!-- success story -->
  <section class="section bg-cover overlay-yt">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-4 position-relative success-video">
          <a class="play-btn venobox" href="https://youtu.be/-0xKiex5z-Q" data-vbtype="video">
            <i class="ti-control-play"></i>
          </a>
        </div>
        <div class="col-lg-6 col-sm-8">
          <div class="bg-white p-5">
            <h2 class="section-title">MENSAGEM FINAL DE ANO 2022</h2>
            <p>O Presidente da Direção dirigiu-se aos associados, dirigentes e amigos da Associação Vale d'Ouro com a tradicional mensagem de ano novo e o lançamento do novo mandato à frente da instituição.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /success story -->
  <app-footer></app-footer>