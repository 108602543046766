import { Component, OnInit } from '@angular/core';
import { SociosService } from 'src/app/services/socios.service';

@Component({
  selector: 'app-partner-search',
  templateUrl: './partner-search.component.html',
  styleUrls: ['./partner-search.component.css']
})
export class PartnerSearchComponent implements OnInit {

  isLoading : boolean = false;
  result    : boolean = false;
  socioName : string = "";
  errorMessage : string = "";
  socioNr   : number = 0;
  nic       : number = 0;
  nif       : number = 0;
  quotaOK   : boolean = false;
  isError   : boolean = false;
  preventMultipleCall : boolean = false;
  nifORnumber : string;

  constructor(private socioService : SociosService) { }

  ngOnInit(): void {
    


  }

  searchSocio(){
    this.preventMultipleCall = true;
    this.socioService.getOneByNIFNRPartner(this.nifORnumber).subscribe((result:any)=>{
      if(result.status){
        this.socioName = result.data.nome;
        this.socioNr = result.data.nr_socio;
        this.nic = result.data.nic;
        this.nif = result.data.nif;
        this.quotaOK = result.quota;
        this.result = true;
        this.isError = false;
      }else{
        this.isError = true;
        this.errorMessage = "O sócio não foi encontrado. Se acha que isto é um erro, por favor contacte o administrador de sistema."
      }

      this.preventMultipleCall = false;
      this.isLoading = false;
    });
  }

}
