<app-login [isAdmin]="isAdmin" (updateAdmin)="updateAdmin()"></app-login>
<app-login-socio [isSocio]="isSocio" (updateSocio)="updateSocio()"></app-login-socio>
<header class="fixed-top header">
  <!-- top header -->
  <div class="top-header py-2 bg-white">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-lg-4 text-center text-lg-left" *ngIf="!isAuthenticated">
          <a class="text-color mr-3" href="tel:+351934392617"><strong>LIGA</strong> +351 93 439 26 17</a>
          <ul class="list-inline d-inline">
            <li class="list-inline-item mx-0"><a class="d-inline-block p-2 text-color" href="https://www.facebook.com/associacaovaledouro/" target="_blank"><i class="ti-facebook"></i></a></li>
            <li class="list-inline-item mx-0"><a class="d-inline-block p-2 text-color" href="https://www.instagram.com/associacaovaledouro/" target="_blank"><i class="ti-instagram"></i></a></li>
          </ul>
        </div>
        <div class="col-lg-4 text-center text-lg-left" *ngIf="isAuthenticated && !isSocio">
          <span class="m-0 headerCustom">Painel de Gestão</span>
        </div>
        <div class="col-lg-4 text-center text-lg-left" *ngIf="isSocio">
          <span class="m-0 headerCustom">Bem-Vindo(a) {{userName}}</span>
        </div>
        <div class="col-lg-8 text-center text-lg-right">
          <ul class="list-inline" *ngIf="!isAuthenticated">
            <li class="list-inline-item"><a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" href="/bilhetes">Bilhetes</a></li>
            <li class="list-inline-item"><a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" href="/donativo">Donativo</a></li>
            <li class="list-inline-item"><a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" href="socio/novo">Torna-te Sócio</a></li>
            <li class="list-inline-item"><a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" href="https://webmail.associacaovaledouro.pt/" target="_blank">Webmail</a></li>
            <li class="list-inline-item"><a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" href="#" data-toggle="modal" data-target="#loginSociosModal">Login Sócios</a></li>
            <li class="list-inline-item"><a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" href="#" data-toggle="modal" data-target="#loginModal"><i class="ti-lock"></i></a></li>
          </ul>
          <ul class="list-inline" *ngIf="isAuthenticated">
            <li class="list-inline-item" *ngIf="isSocio">Quota paga até:&nbsp;<span class="quotaInfo {{quotaFeedBack}}">{{lastPaidQuota | date:'MMM yyyy'}}</span></li>
            <li class="list-inline-item" *ngFor="let router of actualMenuRoutes">
              <a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" routerLink={{router.route}}>{{router.label}}</a>
            </li>
            <li class="list-inline-item" (click)="logout()"><a class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" ><i class="ti-power-off"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- navbar -->
  <div class="navigation w-100">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-dark p-0">
        <a class="navbar-brand" href="index.html"><img src="../../../assets/img/logoCompletoBranco.png" alt="logo" width="200px"></a>
        <button class="navbar-toggler rounded-0" type="button" data-toggle="collapse" data-target="#navigation"
          aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navigation">
          <ul class="navbar-nav ml-auto text-center">
            <li class="nav-item" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
              <a class="nav-link" routerLink="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/sobre-nos">Sobre Nós</a>
            </li>
            
            <li class="nav-item" routerLink="/orgaos" routerLinkActive="active">
              <a class="nav-link" routerLink="/orgaos">Orgãos Sociais</a>
            </li>
            <li class="nav-item" routerLink="/documentos" routerLinkActive="active">
              <a class="nav-link" routerLink="/documentos">documentos</a>
            </li>
            <li class="nav-item" routerLink="/noticias" routerLinkActive="active">
              <a class="nav-link" routerLink="/noticias">Notícias</a>
            </li>
            <li class="nav-item dropdown view">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Atividades
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <!--
                <a class="dropdown-item" routerLink="teacher.html">Portfólio</a>
                -->
                <a class="dropdown-item" routerLink="/atividades/mostra-teatro">MOSTRA DE TEATRO DO DOURO</a>
                <!--
                <a class="dropdown-item" routerLink="notice.html">Academia de Artes Douro e Tâmega</a>
                <a class="dropdown-item" routerLink="notice-single.html">Grupo de Teatro</a>
                <a class="dropdown-item" routerLink="research.html">Grande Debate do Douro</a>
                <a class="dropdown-item" routerLink="scholarship.html">Encontro de Associações</a>
                <a class="dropdown-item" routerLink="course-single.html">Pinhão FUTSAL</a>
                <a class="dropdown-item" routerLink="event-single.html">Para Cá dos Montes / UFM</a>
                -->
              </div>
            </li>
            <li class="nav-item" routerLink="/contactos" routerLinkActive="active">
              <a class="nav-link" routerLink="/contactos">CONTACTOS</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
