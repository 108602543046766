import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-panel-jogos',
  templateUrl: './admin-panel-jogos.component.html',
  styleUrls: ['./admin-panel-jogos.component.css']
})
export class AdminPanelJogosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
