import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {

  anosAtividades = [];
  anosRelatorioContas = [];

  constructor() {

    for(let i = 2009; i <= new Date().getFullYear(); i++){
      this.anosAtividades.push(i);
      if(i != new Date().getFullYear()){
        this.anosRelatorioContas.push(i);
      }
    }

    this.anosAtividades.reverse();
    this.anosRelatorioContas.reverse();
    
   }

  ngOnInit() {
  }

}
