<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <ul class="list-inline custom-breadcrumb">
                    <li class="list-inline-item"><a class="h2 text-primary font-secondary">Pesquisa de Sócio</a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<div class="container mt-3 mb-3">
    <div class="row">
        <div class="col-xl-12 mt-3">
            <div class="input-group mb-3">
                <input type="number" [(ngModel)]="nifORnumber" name="nifORnumber" class="form-control" placeholder="Pesquisa por NIF ou Número de sócio" aria-label="Pesquisa por NIF ou Número de sócio" aria-describedby="button-addon2">
                <button class="btn btn-primary" type="button" id="button-addon2" [disabled]="preventMultipleCall" (click)="searchSocio()">Pesquisar</button>
            </div>
        </div>
    </div>
</div>

<div class="container mt-3 mb-3" *ngIf="result && !isError">
    <div class="row">
        <div class="col-xl-8 mt-3">
            <h2>Sócio</h2>
            <table class="table">
                <tr>
                    <td>Nome:</td>
                    <td><strong>{{socioName}}</strong></td>
                </tr>
                <tr>
                    <td>Nº Sócio:</td>
                    <td><strong>{{socioNr}}</strong></td>
                </tr>
                <tr>
                    <td>NIC:</td>
                    <td><strong>{{nic}}</strong></td>
                </tr>
                <tr>
                    <td>NIF:</td>
                    <td><strong>{{nif}}</strong></td>
                </tr>
                <tr>
                    <td>Quota Válida:</td>
                    <td><span [ngClass]="quotaOK ? 'info-quota-ok' : 'info-quota-nok'">&nbsp;</span></td>
                </tr>
            </table>
                
        </div><div class="col-xl-4">
            <img src="" alt="" width="100%">
            <p class="small text-justify mt-3">
              <strong>INFO</strong> <br />
              A informação apresentada é atualizada a cada leitura do código QR presente nos cartões de sócio. <br/>
              Em caso de pagamento de quota no local, está informação é atualizada em tempo real após recarregar a página ou após efetuar nova leitura do código QR<br/>

            <br /><br />
            <strong>Legenda:</strong><br/>
            <span class="legenda-ok">&nbsp;&nbsp;&nbsp;</span> - Quotização regularizada <br/>
            <span class="legenda-nok">&nbsp;&nbsp;&nbsp;</span> - Quotização não regularizada
            </p>
        </div>
    </div>
</div>

<div class="container mt-3 mb-3" *ngIf="isError">
    <div class="row">
        <div class="col-xl-8">
            <h2>UPS...</h2>
            <p>{{errorMessage}}</p>
        </div>
    </div>
</div>
