import { Component, OnInit } from '@angular/core';
import { SociosService } from '../services/socios.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.css']
})
export class ValidateComponent implements OnInit {

  isLoading : boolean = true;
  result    : boolean;
  socioName : string = "";
  errorMessage : string = "";
  socioNr   : number = 0;
  nic       : number = 0;
  quotaOK   : boolean = false;
  isError   : boolean = false;


  constructor(private socioService : SociosService, private activatedRoute : ActivatedRoute,) { }

  ngOnInit(): void {

    this.socioService.validateSocioCard(this.activatedRoute.snapshot.params.id).subscribe((result:any)=>{

      if(result.status){
        this.socioName = result.data.nome;
        this.socioNr = result.data.nr_socio;
        this.nic = result.data.nic;
        this.quotaOK = result.quota;
        this.result = true;
      }else{
        this.isError = true;
        this.errorMessage = "O sócio não foi encontrado. Se acha que isto é um erro, por favor contacte o administrador de sistema."
      }

      this.isLoading = false;
    });

  }

}
