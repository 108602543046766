import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  validateNIF(nif: string): boolean {
    if (
      nif.length != 9 ||
      (!["1", "2", "3", "5", "6", "8"].includes(nif.substr(0, 1)) &&
        !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(
          nif.substr(0, 2)
        ))
    ) {
      return false;
    }
    const total =
      Number(nif[0]) * 9 +
      Number(nif[1]) * 8 +
      Number(nif[2]) * 7 +
      Number(nif[3]) * 6 +
      Number(nif[4]) * 5 +
      Number(nif[5]) * 4 +
      Number(nif[6]) * 3 +
      Number(nif[7]) * 2;
    const modulo11 = total - Math.trunc(total / 11) * 11;
    const comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11;
    return Number(nif[8]) === comparador;
  }

  validateCC(numeroDocumento) {
    let sum = 0;
    let secondDigit = false;

    if (numeroDocumento.length !== 12) {
      return false;
    }

    for (let i = numeroDocumento.length - 1; i >= 0; --i) {
      try {
        let valor = this.getNumberFromChar(numeroDocumento[i]);

        if (secondDigit) {
          valor *= 2;
          if (valor > 9) {
            valor -= 9;
          }
        }

        sum += valor;
        secondDigit = !secondDigit;
      } catch (e) {
        return false;
      }
    }

    return sum % 10 === 0;
  }

  getNumberFromChar(letter) {
    switch (letter.toUpperCase()) {
      case "0":
        return 0;
      case "1":
        return 1;
      case "2":
        return 2;
      case "3":
        return 3;
      case "4":
        return 4;
      case "5":
        return 5;
      case "6":
        return 6;
      case "7":
        return 7;
      case "8":
        return 8;
      case "9":
        return 9;
      case "A":
        return 10;
      case "B":
        return 11;
      case "C":
        return 12;
      case "D":
        return 13;
      case "E":
        return 14;
      case "F":
        return 15;
      case "G":
        return 16;
      case "H":
        return 17;
      case "I":
        return 18;
      case "J":
        return 19;
      case "K":
        return 20;
      case "L":
        return 21;
      case "M":
        return 22;
      case "N":
        return 23;
      case "O":
        return 24;
      case "P":
        return 25;
      case "Q":
        return 26;
      case "R":
        return 27;
      case "S":
        return 28;
      case "T":
        return 29;
      case "U":
        return 30;
      case "V":
        return 31;
      case "W":
        return 32;
      case "X":
        return 33;
      case "Y":
        return 34;
      case "Z":
        return 35;
    }
    throw new Error("Valor inválido no número de documento.");
  }
}
