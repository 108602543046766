import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FrontEndService } from '../services/front-end.service';

@Component({
  selector: 'app-noticia-single',
  templateUrl: './noticia-single.component.html',
  styleUrls: ['./noticia-single.component.css']
})
export class NoticiaSingleComponent implements OnInit {

  singleNews : any = {};
  isLoading : boolean = true;


  constructor(private frontEndService : FrontEndService, private activatedRoute : ActivatedRoute, private router : Router) { }

  ngOnInit() {

    this.frontEndService.getSingleNews(this.activatedRoute.snapshot.params.id).subscribe((res:any)=>{
      if(res.news != null){
        this.singleNews = res.news;
        setTimeout(() => {
          this.isLoading = false;
        }, 250);
        
      }else{
        this.router.navigate(['noticias']);
      }
      
    });

  }

}
