import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate{

  constructor(private session : SessionService, private router:Router) { }

  canActivate(): boolean {
    if(this.session.isAuthenticated() || this.session.isAuthenticatedSocio()){
      return true;
    }else{
   
      this.router.navigate(['/']);
      return false;
    }
  }
}
