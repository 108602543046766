import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-admin-panel-bilhetes',
  templateUrl: './admin-panel-bilhetes.component.html',
  styleUrls: ['./admin-panel-bilhetes.component.css']
})
export class AdminPanelBilhetesComponent implements OnInit {

  tickets : any;
  season_tickets : any;

  constructor(private adminServive : AdminServiceService) { }

  ngOnInit(): void {

    this.adminServive.getTickets().subscribe((result:any)=>{

      this.tickets = result.tickets;
      this.season_tickets = result.season_tickets;

    });

  }

}
