import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminDestaquesComponent } from './admin-destaques/admin-destaques.component';
import { AdminIndexComponent } from './admin-index/admin-index.component';
import { AdminNoticiasComponent } from './admin-noticias/admin-noticias.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { IndexComponent } from './index/index.component';
import { NoticiaSingleComponent } from './noticia-single/noticia-single.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { OrgaosComponent } from './orgaos/orgaos.component';
import { AuthguardService } from './services/authguard.service';
import { SobreNosComponent } from './sobre-nos/sobre-nos.component';
import { PayQuotaComponent } from './socios/pay-quota/pay-quota.component';
import { AdminguardService } from './services/adminguard.service';
import { SocioguardService } from './services/socioguard.service';
import { DonationComponent } from './donation/donation.component';
import { BilhetesComponent } from './bilhetes/bilhetes.component';
import { NovoSocioComponent } from './novo-socio/novo-socio.component';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { BilheteEpocaComponent } from './bilhete-epoca/bilhete-epoca.component';
import { MostraTreatoComponent } from './atividades/mostra-treato/mostra-treato.component';
import { CartaoComponent } from './socios/cartao/cartao.component';
import { ValidateComponent } from './validate/validate.component';
import { PartnerSearchComponent } from './partner/partner-search/partner-search.component';
import { AdminPanelEventosRelatorioComponent } from './admin/admin-panel-eventos-relatorio/admin-panel-eventos-relatorio.component';
import { MostraTeatroSingleComponent } from './atividades/mostra-teatro-single/mostra-teatro-single.component';

const routes: Routes = [
  {path:'', redirectTo:'/', pathMatch:'full'},
  {path:'', component:IndexComponent},
  {path:'validar/:id', component:ValidateComponent},
  {path:'noticias', component:NoticiasComponent},
  {path:'sobre-nos', component:SobreNosComponent},
  {path:'contactos', component:ContactsComponent},
  {path:'noticias/:id', component:NoticiaSingleComponent},
  {path:'documentos', component:DocumentosComponent},
  {path:'orgaos', component:OrgaosComponent},
  {path:'bilhetes', component:BilhetesComponent},
  //{path:'bilhete-epoca', component:BilheteEpocaComponent, canActivate:[AuthguardService, SocioguardService]},
  {path:'socio/novo', component:NovoSocioComponent},
  {path:'donativo', component:DonationComponent},
  {path:'atividades/mostra-teatro', component:MostraTreatoComponent},
  {path:'atividades/mostra-teatro/:id', component:MostraTeatroSingleComponent},
  {path:'condicoes-acesso', component:DocumentosComponent},
  

  {path:'socio/quota/pagar', component:PayQuotaComponent, canActivate:[AuthguardService, SocioguardService]},
  {path:'socio/cartao', component:CartaoComponent, canActivate:[AuthguardService, SocioguardService]},

  {path:'admin', component:PayQuotaComponent, canActivate:[AuthguardService, AdminguardService]},
  {path:'admin', component:AdminIndexComponent, canActivate:[AuthguardService, AdminguardService]},
  {path:'admin/noticias', component:AdminNoticiasComponent, canActivate:[AuthguardService, AdminguardService], data:{roles:"ac"}},
  {path:'admin/destaques', component:AdminDestaquesComponent, canActivate:[AuthguardService, AdminguardService], data:{roles:"ac"}},
  {path:'admin/panel', component:AdminPanelComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},
  {path:'admin/panel/socios', component:AdminPanelComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},
  {path:'admin/panel/socios/:nrSocio', component:AdminPanelComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},
  {path:'admin/panel/leads', component:AdminPanelComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},
  {path:'admin/panel/donativos', component:AdminPanelComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},
  {path:'admin/panel/bilhetes', component:AdminPanelComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},
  {path:'admin/panel/eventos', component:AdminPanelComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},
  {path:'admin/panel/eventos/:eventId/relatorio', component:AdminPanelEventosRelatorioComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"a"}},

  {path:'parceiro/pesquisa', component:PartnerSearchComponent , canActivate:[AuthguardService, AdminguardService], data:{roles:"p"}},

  {path:'**', redirectTo:'/'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
