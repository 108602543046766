import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BackOfficeService } from 'src/app/services/back-office.service';
import { SessionService } from 'src/app/services/session.service';
import { SociosService } from 'src/app/services/socios.service';

@Component({
  selector: 'app-login-socio',
  templateUrl: './login-socio.component.html',
  styleUrls: ['./login-socio.component.css']
})
export class LoginSocioComponent implements OnInit {

  private readonly notifier: NotifierService;
  
  @ViewChild('closebutton') closebutton;
  @Input() isSocio: boolean;
  @Output() updateSocio = new EventEmitter<Boolean>();

  loginPassword = "";
  loginName = "";
  submitDisabled = true;


  constructor(private sociosService : SociosService, private notifierService : NotifierService, private router : Router, private sessionService : SessionService) { 
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  validateInput(){
    if(this.loginPassword && this.loginPassword !== "" && this.loginName && this.loginName !== ""){
      this.submitDisabled = false;
    }else{
      this.submitDisabled = true;
    }
  }

  login($event){
    this.notifier.hideAll();
    $event.preventDefault();
    this.sociosService.login(this.loginName, this.loginPassword).subscribe((res:any) =>{
      if(res.status){
        this.closebutton.nativeElement.click();        
        localStorage.setItem("ascvdpt_portal", res.token);
        let data = this.sessionService.getJWTData();
        let nameSplited = data.name.split(" ");
        this.notifier.notify("success","Bem-Vindo(a) " + nameSplited[0] + " " +nameSplited[nameSplited.length-1]+"!");
        this.isSocio = true;
        this.updateSocio.emit(true);

        window.location.href = this.router.url;
      }
    },
    (error : HttpErrorResponse) => {
      if(error.status == 401 || error.status == 400){
        this.notifier.notify("error","Dados de login inválidos.");

      }else{
        console.log(error);
        this.notifier.notify("error","Algo inesperado aconteceu. Se o problema persistir, contacte o administrador de sistema.");
      }
      
    });
  }

}
