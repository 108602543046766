import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  private leadURL = environment.apiEndpoint + "/lead";
  private leadNicCheck = environment.apiEndpoint + "/socios/existingNIFNIC?";

  public checkNICNIF(nic,nif){
    let data = {nif, nic};
    let httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
      })
    };
    return this.http.get(this.leadNicCheck+"nic="+nic+"&nif="+nif, httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );

  }

  public createLead(nif, nome, nic, morada, codigo_postal, localidade, email, telemovel, data_nascimento, type, payMethod, mbwNr){
    let data = {nif, nome, nic, morada, codigo_postal, localidade, email, telemovel, data_nascimento, type, payMethod, mbwNr };
    let httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
      })
    };
    return this.http.post(this.leadURL, data, httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }
}
