<section class="section bg-primary">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h6 class="text-white font-secondary mb-0">www.associacaovaledouro.pt/socio/novo</h6>
          <h2 class="section-title text-white" style="text-transform: uppercase;">Torna-te sócio</h2>
          <a href="/socio/novo" class="btn btn-secondary" target="_blank">Clica aqui</a>
        </div>
      </div>
    </div>
</section>
  