import { Component, OnInit } from '@angular/core';
import { TicketsService } from '../services/tickets.service';
import { UtilService } from '../services/util.service';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-bilhete-epoca',
  templateUrl: './bilhete-epoca.component.html',
  styleUrls: ['./bilhete-epoca.component.css']
})
export class BilheteEpocaComponent implements OnInit {

  maxsteps : number = 1;

  isLoading = true;
  step : number = 1;
  isFinalStep : boolean = false;

  payMethod : String;
  mbwNR : String;

  ref: string;
  entity: string;
  value: number;
  maxDate: Date;


  isSocio : boolean = false;

  monthsBehind : number = 0;
  monthsUntilTarget : number;
  SEASON_TICKET_PRICE = 1;
  total : number = this.SEASON_TICKET_PRICE;

  hasPendingStuff = true;
  isPendingMB = false;
  isPendingMBW = false;
  hasTicket = false;


  constructor(private ticketsService : TicketsService, private utilService : UtilService, private sessionService : SessionService) { }

  ngOnInit(): void {

    if(this.sessionService.isAuthenticatedSocio()){
      this.isSocio = true;
      if(this.sessionService.isAuthenticatedSocio()){
        this.isSocio = true;
        let socioData = this.sessionService.getJWTData();
        if(socioData.tipo_socio == 5 || socioData.tipo_socio == 6){
          window.location.href="/";
        }
      }
    }

    this.ticketsService.hasPendingSeasonTickets().subscribe((resultSocioCheck:any)=>{

      if(!resultSocioCheck.isPendingMB && !resultSocioCheck.isPendingMBW && !resultSocioCheck.hasTicket){
        this.ticketsService.getQuotaInfo().subscribe((result:any)=>{
      
          if(result.status){
            if(result.quotasBehind <= 0 && result.monthsUntilTarget < 0){
              this.monthsBehind =  Math.abs(result.quotasBehind);
              this.monthsUntilTarget =  Math.abs(result.monthsUntilTarget);
              this.total += this.monthsBehind + this.monthsUntilTarget;
            }
    
            if(result.quotasBehind > 0 && result.quotasBehind + result.monthsUntilTarget < 0){
              this.monthsUntilTarget = Math.abs(result.quotasBehind + result.monthsUntilTarget);
              this.total += this.monthsUntilTarget;
            }
          }
          this.hasPendingStuff = false;
          this.isLoading = false;
    
        });
      }else if(resultSocioCheck.isPendingMB){

        this.isPendingMB = true;
        this.isLoading = false;
      }else if(resultSocioCheck.isPendingMBW){

        this.isPendingMBW = true;
        this.isLoading = false;
      }
      
      if(resultSocioCheck.hasTicket){
        



        this.isPendingMB = false;
        this.isPendingMBW = false;
        this.hasTicket = true;
        this.isLoading = false;
      }

    });

    

  }

  stepBack(){
    this.step -= 1;
  }

  buySeasonTicket(){

    this.isLoading = true;

    this.ticketsService.createSeasonTicketForPayment(this.payMethod, this.mbwNR).subscribe((result : any)=>{

      if(result.status){

        this.ref = result.ref;
        this.value = result.value;
        this.entity = result.entity;
        this.maxDate = result.maxDate;

        this.step = -1;
        this.isFinalStep = true;
        this.isLoading = false;
      }

    });

  }

  selectPayMethod(event, paymethod) {
    this.payMethod = paymethod;

    let elements = document.getElementsByClassName("activePayMethod");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("activePayMethod");
    }

    let element = event.target;
    while (element.nodeName != "LI") {
      element = element.parentElement;
    }

    element.classList.add("activePayMethod");

    this.validatePaymentOptions();
  }

  isValidPayOptions: boolean = false;

  validatePaymentOptions() {
    if (this.step == 1) {
      if (this.payMethod != "MB" && this.payMethod != "MBW") {
        this.isValidPayOptions = false;
        return false;
      }

      if (this.payMethod == "MBW") {
        let availablePrefix = ["91", "92", "93", "96"];
        if (
          !this.mbwNR ||
          this.mbwNR.length != 9 ||
          !availablePrefix.includes(this.mbwNR[0] + this.mbwNR[1])
        ) {
          this.isValidPayOptions = false;
          //toast erro
          return false;
        }
      }
    }
    this.isValidPayOptions = true;
  }


}
