import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  httpOptions = {
    headers: new HttpHeaders({
       'Content-Type': 'application/json; charset=UTF-8'
    })
  };

  constructor(private http: HttpClient, private jwtHelper : JwtHelperService) { }

  public isAuthenticated() : boolean{
    const token = localStorage.getItem('ascvdpt_portal');
    let isAdmin = false;
    try{
      let jwt = this.jwtHelper.decodeToken(token);
      isAdmin = jwt.id != null;
    }catch(e){ 
      localStorage.removeItem('ascvdpt_portal');
      return false;
    }    
    return token != null && isAdmin ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  public isAuthenticatedSocio() : boolean{
    const token = localStorage.getItem('ascvdpt_portal');
    let isSocio = false;
    try{
      let jwt = this.jwtHelper.decodeToken(token);
      isSocio = jwt.nr_socio != null;
    }catch(e){
      localStorage.removeItem('ascvdpt_portal');
      return false;
    }    
    return token != null && isSocio ? !this.jwtHelper.isTokenExpired(token) : false;
  }


  public getJWTData() : any {
    const token = localStorage.getItem('ascvdpt_portal');
    if(token != null){
      try{
        return this.jwtHelper.decodeToken(token);
      }catch(e){
        localStorage.removeItem('ascvdpt_portal');
        return false;
      }     
    }else{
      return null;
    }
  }

  public getToken(){
    return localStorage.getItem("ascvdpt_portal");
  }
}
