import { Component, OnInit } from '@angular/core';
import { SociosService } from '../services/socios.service';
import { SessionService } from '../services/session.service';
import { NotifierService } from 'angular-notifier';
import { DonationsService } from '../services/donations.service';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.css']
})
export class DonationComponent implements OnInit {

  constructor(
    private donationService : DonationsService,
    private notifierService : NotifierService,
    private sessionService : SessionService
 ) { }

 isLoading : boolean = true;
 step : number = 1;
 maxsteps : number = 2;
 isFinalStep : boolean = false;

 payMethod : String;
 isValid : boolean = false;
 mbwNR : string;
 ref : string;
 entity : string;
 value : number;
 maxDate : Date;

 name : string;
 nif : string;
 email : string;

 ngOnInit() {

  if(this.sessionService.isAuthenticatedSocio()){
    let socioData = this.sessionService.getJWTData();
    this.name = socioData.name;
    this.nif = socioData.nif+"";
    this.email = socioData.email;
  }

   this.isLoading = false;

 }


 nextStep(){
   this.step += 1;
   this.validate();
 }

 stepBack(){
   this.step -= 1;
 }

 pay(){
   this.isLoading = true;

   //name, nif, email, value, method, phone
   this.donationService.payDonation(this.name, this.nif, this.email, this.value, this.payMethod, this.mbwNR).subscribe((res:any)=>{

    if(res.status){
      
      if(this.payMethod == 'mb'){         
        this.entity = res.entity;
      }
      this.value = res.value;
      this.ref = res.ref;
      this.maxDate = res.maxDate;

      this.step = 0;
      this.isFinalStep = true;
      this.isLoading = false;

    }else{

      this.notifierService.notify("error","Não foi possível processar o seu pedido. Se o problema persistir contact o administrador do site.");

    }

  });


 }

 selectPayMethod(event, paymethod){
   this.payMethod = paymethod;

   let elements = document.getElementsByClassName("activePayMethod");
   for(let i = 0; i < elements.length; i++){
     elements[i].classList.remove("activePayMethod");
   }
   
   let element = event.target;
   while(element.nodeName != "LI"){
     element = element.parentElement;
   }

   element.classList.add("activePayMethod");
   
   this.validate();

 }

 validate(){
   this.isValid = true;

   if(this.name.length < 3){
    this.isValid = false;
   }

   if(!this.nif || !this.validateNIF(this.nif)){
    this.isValid = false;
   }

   var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

   if(!this.email || !this.email.match(emailRegex)){
    this.isValid = false;
    
   }

   if(!this.value || this.value <= 9){
    this.isValid = false;
    
   }

   if(this.step == 2){
    if(this.payMethod != 'mb' && this.payMethod != 'mbw'){
      this.isValid = false;
    }

    if(this.payMethod == 'mbw'){
      let availablePrefix = ["91", "92", "93", "96"];
      if(!this.mbwNR || this.mbwNR.length != 9 || !availablePrefix.includes(this.mbwNR[0]+this.mbwNR[1])){
        this.isValid = false;
        //toast erro
      }
    }
   }
 
 }

 validateNIF(nif: string): boolean {
  if (!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) && !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))) { 
    return false; 
  } 
  const total = Number(nif[0]) * 9 + Number(nif[1]) * 8 + Number(nif[2]) * 7 + Number(nif[3]) * 6 + Number(nif[4]) * 5 + Number(nif[5]) * 4 + Number(nif[6]) * 3 + Number(nif[7]) * 2;
  const modulo11 = total - Math.trunc(total / 11) * 11; const comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11; 
  return Number(nif[8]) === comparador; 
}

}
