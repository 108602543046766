<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
        </div>
      </div>
    </div>
  </section>
  <!-- /page title -->
  
  <!-- blog details -->
  <section class="section-sm bg-gray" *ngIf="singleNews.image != null">
    <div class="container">
      <div class="row">
        <div class="col-6 mb-4">
          <img src="{{singleNews.image}}" alt="blog-thumb" class="img-fluid w-100">
        </div>
        <div class="col-12">
          <ul class="list-inline">
            <li class="list-inline-item mr-4 mb-3 mb-md-0 text-light">{{singleNews.createdAt | date:'fullDate'}}, {{singleNews.author_user.name}}</li>
          </ul>
        </div>
        <!-- border -->
        <div class="col-12 mt-4">
          <div class="border-bottom border-primary"></div>
        </div>
        <!-- blog contect -->
        <div class="col-12 mb-5">
          <br />
          <h2>{{singleNews.title}} </h2>
          <p style="font-weight: bold" [innerHTML]="singleNews.short_description"></p>
          <br />
          <div [innerHTML]="singleNews.body" style="text-align: justify;text-justify: inter-word;"></div>
        </div>
      </div>
    </div>
  </section>
  <!-- /blog details -->
  <app-footer></app-footer>