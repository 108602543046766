<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item"><a class="h2 text-primary font-secondary">Documentos</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- /page title -->

<!-- notice -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="h3">Planos de Atividades</p>
        <ul class="list-unstyled">
          <!-- notice item -->          
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow"  *ngFor="let ano of anosAtividades">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">1</span> JAN {{ano}}</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.PATIV.{{ano}}.pdf" class="h4 mb-3 d-block" target="_blank">Plano de Atividades {{ano}}</a>
              <p class="mb-0">Plano de Atividades e Orçamento.</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.PATIV.{{ano}}.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
        </ul>
      </div>
      
      <div class="col-12 mt-5">
        <p class="h3">Relatórios de Atividades e Contas</p>
        <ul class="list-unstyled">
          <!-- notice item -->          
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow"  *ngFor="let ano of anosRelatorioContas">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">31</span> DEZ {{ano}}</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.RATC.{{ano}}.pdf" class="h4 mb-3 d-block" target="_blank">Relatório de Atividades e Contas {{ano}}</a>
              <p class="mb-0">Relatório de Atividades e Contas</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.RATC.{{ano}}.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
        </ul>
      </div>

      <div class="col-12 mt-5">
        <p class="h3">Organização Interna</p>
        <ul class="list-unstyled">
          <!-- notice item -->      
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">30</span> SET 2019</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.PGST.2019-2023.pdf" class="h4 mb-3 d-block" target="_blank">Plano de Gestão 2019-2023</a>
              <p class="mb-0">Plano de Gestão</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.PGST.2019-2023.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>    
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">7</span> JAN 2017</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.PGST.2017-2021.pdf" class="h4 mb-3 d-block" target="_blank">Plano de Gestão 2017-2021</a>
              <p class="mb-0">Plano de Gestão</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.PGST.2017-2021.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">2</span> JAN 2015</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.PGST.2014-2016.pdf" class="h4 mb-3 d-block" target="_blank">Plano de Gestão 2014-2016</a>
              <p class="mb-0">Plano de Gestão</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.PGST.2014-2016.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">3</span> NOV 2012</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.PGST.2012-2014.pdf" class="h4 mb-3 d-block" target="_blank">Plano de Gestão 2012-2014</a>
              <p class="mb-0">Plano de Gestão</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.PGST.2012-2014.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">6</span> MAR 2020</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.GRL.PC-19.pdf" class="h4 mb-3 d-block" target="_blank">Plano de Contingência</a>
              <p class="mb-0">Para resposta a cenário de epidemia pelo novo coronavirus(Covid-19).</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.GRL.PC-19.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
        </ul>
      </div>

      <div class="col-12 mt-5">
        <p class="h3">Regulamentos</p>
        <ul class="list-unstyled">
          <!-- notice item -->          
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">1</span> DEZ 2018</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.REG.INTERNO.pdf" class="h4 mb-3 d-block" target="_blank">Regulamento Interno</a>
              <p class="mb-0">Regulamento Interno</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.REG.INTERNO.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">1</span> DEZ 2020</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.REG.AFUTSAL.pdf" class="h4 mb-3 d-block" target="_blank">Academia de Futsal</a>
              <p class="mb-0">Regulamento Interno e Código de Conduta</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.REG.AFUTSAL.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">1</span> OUT 2018</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.REG.AADT.pdf" class="h4 mb-3 d-block" target="_blank">Academia de Artes Douro e Tâmega</a>
              <p class="mb-0">Regulamento Interno</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.REG.AADT.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">30</span> SET 2016</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.REG.PRIVACIDADE.pdf" class="h4 mb-3 d-block" target="_blank">Política de Privacidade</a>
              <p class="mb-0">Política de Privacidade e Proteção de Dados Pessoais.
                 Termos e condições de utilização das páginas das redes sociais geridas pela instituição</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.REG.PRIVACIDADE.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>
        </ul>
      </div>
      <div class="col-12 mt-5">
        <p id="estatutos" class="h3">Estatutos</p>
        <ul class="list-unstyled">
          <!-- notice item -->      
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">21</span> NOV 2016</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.ESTATUTOS-ALTERACAO01.pdf" class="h4 mb-3 d-block" target="_blank">Estatutos</a>
              <p class="mb-0">Primeira alteração</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.ESTATUTOS-ALTERACAO01.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>    
          <li class="d-md-table mb-4 w-100 border-bottom hover-shadow">
            <div class="d-md-table-cell text-center p-4 bg-primary text-white mb-4 mb-md-0 data"><span
                class="h2 d-block">24</span> JUN 2008</div>
            <div class="d-md-table-cell px-4 vertical-align-middle mb-4 mb-md-0">
              <a href="../../assets/docs/ASCVD.ESTATUTOS.pdf" class="h4 mb-3 d-block" target="_blank">Estatutos</a>
              <p class="mb-0">Contituição da Associação</p>
            </div>
            <div class="d-md-table-cell text-right pr-0 pr-md-4"><a href="../../assets/docs/ASCVD.ESTATUTOS.pdf" target="_blank"
                class="btn btn-primary">Descarregar</a></div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</section>
<!-- /notice -->
<app-footer></app-footer>