<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item"><a class="h2 text-primary font-secondary">Donativo</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container dona mt-3 quotas" *ngIf="step == 1">
    <p>Todos os donativos são anónimos. Os dados recolhidos destinam-se exclusivamente a tratamento fiscal.</p>
    <p class="small">passo {{step}} de {{maxsteps}}</p>
    <ul class="list-group">
        <div class="input-group w-100">           
            <input type="text" class="form-control" placeholder="Nome" name="name" [(ngModel)]="name" (keyup)="validate()">
        </div> 
        <div class="input-group w-100 mt-3">           
            <input type="text" class="form-control" placeholder="NIF" name="nif" [(ngModel)]="nif" (keyup)="validate()">
        </div> 
        <div class="input-group w-100 mt-3">           
            <input type="text" class="form-control" placeholder="Email" name="email" [(ngModel)]="email" (keyup)="validate()">
        </div> 
        <div class="input-group w-100 mt-3">           
            <input type="number" min="10" class="form-control" placeholder="Valor (€)" name="value" [(ngModel)]="value" (keyup)="validate()">
        </div>         
    </ul>
      
    <div class="row mt-3">
        <div class="col-xl-4">

        </div>
        <div class="col-xl-4">

        </div>
        <div class="col-xl-4">
            <button class="btn btn-primary w-100 mb-5" (click)="nextStep()" [disabled]="!isValid">Seguinte</button>
        </div>
    </div>
      
      <div class="clearfix mb-5"></div>
</div>
<div class="container mt-3 quotas" *ngIf="step == 2">
  <p>Escolhe a forma de pagamento</p>
  <p class="small">passo {{step}} de {{maxsteps}}</p>
  <ul class="list-group">
    <li class="list-group-item" (click)="selectPayMethod($event, 'mb')"  [class.activeQuota]="payMethod == 'mb'">
        <span><img src="../../../assets/img/mb_logo.png" width="50px" alt=""><strong class="ml-3">Referência Multibanco</strong><span *ngIf="payMethod == 'mb'" class="small">&nbsp;&nbsp;&nbsp; Os dados de pagamento ficam disponíveis na confirmação da encomenda.</span></span>
                  
    </li><br />
    <li class="list-group-item" (click)="selectPayMethod($event, 'mbw')" [class.activeQuota]="payMethod == 'mbw'">
      <span><img src="../../../assets/img/mbway_logo.png" width="50px" alt=""><strong class="ml-3">MB WAY</strong><span *ngIf="payMethod == 'mbw'" class="small">&nbsp;&nbsp;&nbsp; Para finalizar introduza o número de telemóvel associado ao MB WAY.</span></span>
      <div class="input-group w-100" *ngIf="payMethod == 'mbw'">
        <div class="input-group-prepend">
          <span class="input-group-text" id=""><img src="../../../assets/img/flag_pt.png" width="25px" alt="">&nbsp;+351</span>
        </div>
        <input type="text" class="form-control" placeholder="Telemóvel" name="mbwNR" [(ngModel)]="mbwNR" (keyup)="validate()">
      </div>            
  </li><br />
  </ul>
  <div class="row">
    <div class="col-md-4"><button class="btn btn btn-outline-warning w-100 mb-1" (click)="stepBack()">Voltar</button></div>
    <div class="col-md-4"></div>
    <div class="col-md-4"><button class="btn btn-primary w-100" (click)="pay()" [disabled]="!isValid">Confirmar</button></div>
  </div>
  <div class="clearfix mb-5"></div>
</div>
<div class="container mt-3 donation" *ngIf="isFinalStep">
  <img src="../../assets/img/logoCompleto.png" alt="" width="350px" class="print-no_display"><br class="print-no_display" /><br class="print-no_display" />
  <p class="alert" *ngIf="payMethod == 'mb'">Os dados abaixo foram enviados para o e-mail indicado e não estarão disponíveis para consulta mais tarde nesta página!
    Caso pretenda guardar estes dados imprima a página ou consulte o seu e-mail.</p>
  
  <div *ngIf="payMethod == 'mb'" class="mt-3">
    <h6 class="print-h1">Obrigado pelo seu contributo</h6>
    <div class="print-text">
      
      <img src="../../assets/img/mb_logo.png" width="100px" alt="" class="print-img" /><br />
      <table style="margin-left: auto;margin-right: auto;">
        <tr>
          <td class="font-weight-bold">Entidade:</td>
          <td>{{entity}}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Referência:</td>
          <td>{{ref}}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Valor:</td>
          <td>{{value | currency:'EUR' }}</td>
        </tr>
      </table>

      <span class="small">Referência válida até {{maxDate | date}} </span><br /><br />

      <p>Quando confirmado o pagamento receberá as restantes informações no seu e-mail.</p>
      <button class="btn btn-info" onclick="window.print();return false;">Imprimir&nbsp;&nbsp;&nbsp;<i class="ti-printer"></i></button>
      <div style="min-height: 75px;"></div>
    </div>
  </div>
  <div *ngIf="payMethod == 'mbw'" class="mt-3">
    <div style="min-height: 210px;"></div>
    <h6 class="print-h1">Obrigado pelo seu contributo</h6>
    <p>Foi enviado para o telemóvel <strong>{{mbwNR}}</strong> o pedido de pagamento, no valor de <strong>{{value | currency:'EUR' }}</strong>.</p>
    <div style="min-height: 210px;"></div>
  </div>
  
  
  <div class="clearfix mb-5"></div>
</div>
<!-- /page title -->


<!-- /notice -->
<app-footer></app-footer>