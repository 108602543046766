<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>

<!-- page title -->
<section class="page-title-section overlay">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <ul class="list-inline custom-breadcrumb">
            <li class="list-inline-item"><a class="h2 text-primary font-secondary">Bilhete Época</a></li>
            <li class="list-inline-item text-white h3 font-secondary @@nasted"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- /page title -->

<div class="section" *ngIf="hasPendingStuff" style="min-height: 497px;">
  <div class="container" >
    <div class="row">
      <div class="col-xl-12"> 
        <h4 *ngIf="isPendingMBW || isPendingMB" class="text-center">Já efetuou um pedido de emissão que se encontra em pagamento via <span *ngIf="isPendingMB">Multibanco</span><span *ngIf="isPendingMBW">MB Way</span>.</h4>
        <p *ngIf="isPendingMBW || isPendingMB" class="text-center mt-3">Caso tenha alguma dúvida ou dificuldade por favor contacte-nos via <a href="mailto:geral@associacaovaledouro.pt">email.</a></p>
        <h4 *ngIf="hasTicket" class="text-center">Obrigado!<br />Já possui um bilhete de época.</h4>
        <p *ngIf="hasTicket" class="text-center mt-3">Caso não tenha recebido no seu email o pdf com o bilhete ou caso o tenha perdido por favor contacte-nos via <a href="mailto:geral@associacaovaledouro.pt">email.</a></p>
        <br />
        <img src="../../assets/img/bilhete_epoca.png" alt="" width="300px" style="display: block;margin: 0 auto;">
      </div>
      
    </div>
  </div>
</div>

<div class="section" *ngIf="step == 1 && !hasPendingStuff" style="min-height: 497px; padding-top: 0px;">
  <div class="container">
    <p class="small mt-3">passo {{ step }} de {{ maxsteps }}</p>
    <div class="row mt-3" style="min-height: 325px; padding-top: 0px;">
      <div class="col-xl-6 preview_ticket">
        <img src="../../assets/img/bilhete_epoca.png" alt="" width="300px">
      </div>
      <div class="col-xl-6 mt-3">
        <div class="mt-4">
          <h5>Resumo da compra</h5>
          <table class="table">
            <thead>
              <td>Item</td>
              <td>Qtd.</td>
              <td>Preço</td>
            </thead>
            <tbody>
              <tr>
                <td>Bilhete de Época</td>
                <td>1</td>
                <td>{{SEASON_TICKET_PRICE|currency:'EUR'}}</td>
              </tr>
              <tr *ngIf="monthsBehind > 0">
                <td>Quotas em atraso</td>
                <td>{{monthsBehind}}</td>
                <td>{{monthsBehind|currency:'EUR'}}</td>
              </tr>
              <tr *ngIf="monthsUntilTarget > 0">
                <td>Quotas até Abril 2024</td>
                <td>{{monthsUntilTarget}}</td>
                <td>{{monthsUntilTarget|currency:'EUR'}}</td>
              </tr>
              <tr class="total">
                <td>Total</td>
                <td></td>
                <td>{{total|currency:'EUR'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container mt-3 quotas">
      <h5>Escolhe a forma de pagamento</h5>
      <ul class="list-group">
        <li class="list-group-item" (click)="selectPayMethod($event, 'MB')" [class.activeQuota]="payMethod == 'MB'" *ngIf="total > 1">
          <span><img src="../../../assets/img/mb_logo.png" width="50px" alt="" /><strong class="ml-3">Referência
              Multibanco</strong><span *ngIf="payMethod == 'MB'" class="small">&nbsp;&nbsp;&nbsp; Os dados de pagamento
              ficam disponíveis na
              confirmação da encomenda.</span></span>
        </li>
        <br />
        <li class="list-group-item" (click)="selectPayMethod($event, 'MBW')" [class.activeQuota]="payMethod == 'MBW'">
          <span><img src="../../../assets/img/mbway_logo.png" width="50px" alt="" /><strong class="ml-3">MB
              WAY</strong><span *ngIf="payMethod == 'MBW'" class="small">&nbsp;&nbsp;&nbsp; Para finalizar introduza o
              número de telemóvel
              associado ao MB WAY.</span></span>
          <div class="input-group w-100" *ngIf="payMethod == 'MBW'">
            <div class="input-group-prepend">
              <span class="input-group-text" id=""><img src="../../../assets/img/flag_pt.png" width="25px"
                  alt="" />&nbsp;+351</span>
            </div>
            <input type="text" class="form-control" placeholder="Telemóvel" name="mbwNR" [(ngModel)]="mbwNR"
              (keyup)="validatePaymentOptions()" />
          </div>
        </li>
        <br />
      </ul>
      <div class="row">
        <div class="col-md-4">
          <button class="btn btn btn-outline-warning w-100 mb-1" routerLink="/bilhetes">
            Voltar
          </button>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <button class="btn btn-primary w-100" [disabled]="!isValidPayOptions" (click)="buySeasonTicket()">
            Comprar
          </button>
        </div>
      </div>
      <div class="clearfix mb-5"></div>
    </div>
  </div>

</div>

  
  <div class="container mt-3 ticket_final_step" *ngIf="isFinalStep">
    <h3 class="text-left mt-3 mb-0 p-0">Compra de bilhete</h3>
    <br class="print-no_display" />
    <p class="alert mt-3" *ngIf="payMethod == 'MB'">
      Os dados abaixo foram enviados para o e-mail indicado e não estarão
      disponíveis para consulta mais tarde nesta página! Caso pretenda guardar
      estes dados imprima a página ou consulte o seu e-mail.
    </p>
  
    <div *ngIf="payMethod == 'MB'" class="mt-3">
      <div class="print-text">
        <img src="../../assets/img/mb_logo.png" width="100px" alt="" class="print-img" /><br />
        <table style="margin-left: auto; margin-right: auto">
          <tr>
            <td class="font-weight-bold">Entidade:</td>
            <td>{{ entity }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Referência:</td>
            <td>{{ ref }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Valor:</td>
            <td>{{ value | currency : "EUR" }}</td>
          </tr>
        </table>
  
        <span class="small">Referência válida até {{ maxDate | date }} </span><br /><br />
  
        <p>
          Quando confirmado o pagamento receberá o bilhete no seu
          e-mail.
        </p>
        <button class="btn btn-info" onclick="window.print();return false;">
          Imprimir&nbsp;&nbsp;&nbsp;<i class="ti-printer"></i>
        </button>
        <div style="min-height: 75px"></div>
      </div>
    </div>
    <div *ngIf="payMethod == 'MBW'" class="mt-1">
      <div style="min-height: 75px"></div>
      <img src="../../assets/img/mbway_logo.png" width="100px" alt="" class="print-img" /><br />
      <p>
        Foi enviado para o telemóvel <strong>{{ mbwNR }}</strong> o pedido de
        pagamento.
      </p>
      <p class="small">Dispõe de 4 minutos para efetuar o pagamento. <br />Receberá o bilhete no email indicado após confirmação do pagamento.</p>
      <div style="min-height: 43px"></div>
    </div>
  
    <div class="clearfix mb-5"></div>
  </div>



<app-footer></app-footer>