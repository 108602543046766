import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { SociosService } from "src/app/services/socios.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  isAuthenticated : boolean;
  isSocio: boolean;
  isAdmin: boolean;
  isJournalist: boolean;
  isPartner: boolean;
  userName: string;
  lastPaidQuota: string;
  quotaFeedBack: string;
  role : string;

  menuRoutes : any = [{
    route : "/admin/panel/socios",
    label : "Gestão",
    permissions : ["a"]
  },
  {
    route : "/admin/destaques",
    label : "Destaques",
    permissions : ["a", "c"]
  },
  {
    route : "/admin/noticias",
    label : "Notícias",
    permissions : ["a", "c"]
  },
  {
    route : "/parceiro/pesquisa",
    label : "Pesquisa Associado",
    permissions : ["p"]
  },
  {
    route : "/socio/cartao",
    label : "Cartão de Sócio",
    permissions : ["s"]
  },
  {
    route : "/socio/quota/pagar",
    label : "Pagar Quotas",
    permissions : ["s"]
  },
  {
    route : "/bilhetes",
    label : "Bilhetes",
    permissions : ["s"]
  },
  {
    route : "/donativo",
    label : "Donativo",
    permissions : ["s"]
  }];

  actualMenuRoutes : any = [];

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private socioService: SociosService
  ) {}

  ngOnInit() {
    this.updateAdmin();
    this.updateSocio();
  }

  logout() {
    localStorage.removeItem("ascvdpt_portal");
    this.router.navigate(["/"]);
    this.isAdmin = false;
    this.isSocio = false;
    this.isJournalist = false;
    this.actualMenuRoutes = [];
    this.isAuthenticated = false;
  }

  updateAdmin() {
    if (this.sessionService.isAuthenticated()) {
      let data = this.sessionService.getJWTData();
      this.userName = data.name;
      this.role = data.role;
      this.isAuthenticated = true;
      if(data.role.includes("a")){
        this.isAdmin = true;
      }
      if(data.role.includes("p")){
        this.isPartner = true;
      }
      if(data.role.includes("c")){
        this.isJournalist = true;
      }

      this.role.split(";").forEach((role)=>{
        this.menuRoutes.forEach((route)=>{
          if(route.permissions.includes(role) && !this.actualMenuRoutes.includes(route)){
            this.actualMenuRoutes.push(route);
          }
        });
      });

      
    }
  }

  updateSocio() {
    if (this.sessionService.isAuthenticatedSocio()) {
      this.isSocio = true;
      let data = this.sessionService.getJWTData();
      this.menuRoutes.forEach((route)=>{
          if(route.permissions.includes("s") && !this.actualMenuRoutes.includes(route)){
            this.actualMenuRoutes.push(route);
          }
      });
      this.isAuthenticated = true;
      let splited = data.name.split(" ");
      this.userName =
        splited[0] +
        " " +
        splited[splited.length - 1] +
        " | Nº Sócio: " +
        data.nr_socio;
      this.socioService.getLastPaidQuota().subscribe((res: any) => {
        if (res.status) {
          this.lastPaidQuota = res.quota[0].data_fim;

          let paidQuota = this.lastPaidQuota.split("-");
          let quotaYear = parseInt(paidQuota[0]);
          let quotaMonth = parseInt(paidQuota[1]);

          let now = new Date();
          var month = now.getMonth() + 1;
          var year = now.getFullYear();

          if (month == quotaMonth && year == quotaYear) {
            this.quotaFeedBack = "warning";
          } else if(quotaYear > year || (quotaMonth > month && quotaYear == year ) ){
            this.quotaFeedBack = "paid";
          }else{
            this.quotaFeedBack = "past";
          }
          
        }
      });
    }
  }
}
