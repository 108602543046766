<div class="rounded bg-white mb-5">
    <div class="row">
        <div class="col-xl-6 border-right">
            <div class="">
                <div class="d-flex flex-column align-items-center text-center ">
                    <img class="rounded-circle" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg">
                    <span class="font-weight-bold">Nº: {{socio.nr_socio}}</span>
                    <span class="font-weight-bold">{{socio.nome}}</span>
                    <span class="text-black-50">{{socio.email}}</span>
                </div>

                <div class="row mt-3">
                    <div class="col-md-12"><label class="labels">Nome</label><input type="text" class="form-control" value="{{socio.nome}}" [attr.disabled]="isEditing"></div>
                    <div class="col-md-12 mt-2"><label class="labels">Email</label><input type="text" class="form-control" value="{{socio.email}}" [attr.disabled]="isEditing"></div>                 
                </div>
                <div class="row mt-3">
                    <div class="col-md-6 mt-2"><label class="labels">NIC</label><input type="text" class="form-control" value="{{socio.nic}}" [attr.disabled]="isEditing"></div>
                    <div class="col-md-6 mt-2"><label class="labels">Telemovel</label><input type="text" class="form-control" value="{{socio.telemovel}}" [attr.disabled]="isEditing"></div>
                </div>
                <div class="row mt-3">  

                    <div class="col-md-12 mt-2"><label class="labels">Nome</label><input type="text" class="form-control" value="{{socio.morada}}" [attr.disabled]="isEditing"></div>

                </div>
                <div class="row mt-3">
                    <div class="col-md-6"><label class="labels">Codigo-Postal</label><input type="text" class="form-control" placeholder="country" value="{{socio.codigo_postal}}" [attr.disabled]="isEditing"></div>
                    <div class="col-md-6"><label class="labels">Localidade</label><input type="text" class="form-control" value="{{socio.localidade}}" placeholder="state" [attr.disabled]="isEditing"></div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6"><label class="labels">Data Nascimento</label><input type="text" class="form-control" placeholder="country" value="{{socio.data_nascimento}}" [attr.disabled]="isEditing"></div>
                    <div class="col-md-6"><label class="labels">Data Admissão</label><input type="text" class="form-control" value="{{socio.data_admissao}}" placeholder="state" [attr.disabled]="isEditing"></div>
                </div>
                <div *ngIf="isEditing" class="mt-5 text-center"><button class="btn btn-primary profile-button" type="button" disabled>Guardar</button></div>
                <div *ngIf="!isEditing" class="mt-5 text-center"><button class="btn btn-primary profile-button" type="button" disabled>Editar</button></div>

            </div>
        </div>
        <div class="col-xl-6">
            <div class="p-2 py-2">
                <h4 class="text-center">Quotas</h4>
            </div>
            <table class="table table-striped table-sm">
                <thead>
                  <tr>
                    <th scope="col">Id EuPago</th>
                    <th scope="col">metodo</th>
                    <th scope="col">valor</th>
                    <th scope="col">estado</th>
                    <th scope="col">data_inicio</th>
                    <th scope="col">data_fim</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let quota of socio.quota">
                    <td style="overflow: hidden; max-width:20vw; text-overflow:ellipsis" title="{{quota.id_eupago}}">{{quota.id_eupago}}</td>
                    <td>{{quota.metodo}}</td>
                    <td>{{quota.valor}}</td>
                    <td>{{quota.estado}}</td>
                    <td>{{quota.data_inicio}}</td>
                    <td>{{quota.data_fim}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
</div>
