<app-loader [isOpen]="isLoading"></app-loader>
<app-header></app-header>
<!-- page title -->
<section class="page-title-section overlay" data-background="images/backgrounds/page-title.jpg">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul class="list-inline custom-breadcrumb">
          <li class="list-inline-item"><a class="h2 text-primary font-secondary">Gestão Notícias</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container mt-3">
  <div class="row" *ngIf="isEdit || isNew">
    <app-admin-noticias-factory [(isEdit)]="isEdit" [(isNew)]="isNew" [(newsObject)]="newsObject" (handleNewsUpdate)="handleNewsChange()"></app-admin-noticias-factory>
  </div>
  <div class="row" *ngIf="!isEdit && !isNew">
      <div class="col-xl-12 mb-4">
          <button class="btn btn-primary w-100" (click)="handleNewNews()">Criar Notícia</button>
      </div>
      <div class="col-xl-12"  style="min-height: 375px;">
          <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Título</th>
                  <th scope="col">Descrição</th>
                  <th scope="col">Data criação</th>
                  <th scope="col">Data atualização</th>
                  <th scope="col">Autor</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let news of newsLst">
                  <td><button class="btn btn-sm btn-info" (click)="handleEditNews(news.uuid)">Editar</button></td>
                  <td>{{news.title}}</td>
                  <td [innerHTML]="news.short_description"></td>
                  <td>{{news.createdAt}}</td>
                  <td>{{news.updatedAt}}</td>
                  <td>{{news.author_user.name}}</td>
                  
                </tr>
              </tbody>
            </table>
      </div>
      <div class="col-xl-12 mb-4">
        <button class="btn btn-primary w-100" (click)="handleNewNews()">Criar Notícia</button>
    </div>
  </div>
</div>
<!-- /page title -->


<!-- /notice -->
<app-footer></app-footer>