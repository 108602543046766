<header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <p class="navbar-brand col-md-3 col-lg-2 me-0 px-3 company-name">Painel de Gestão</p>
    <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse"
        data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <input class="form-control form-control-dark w-100 search" type="text" placeholder="Pesquisa" aria-label="Search">
    <div class="">
        <div class="nav-item text-nowrap">
            <button class="btn btn-outline-danger white" routerLink="/">Portal</button>
        </div>
    </div>
</header>

<div class="container-fluid">
    <div class="row">
        <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div class="position-sticky pt-3">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/admin/panel/socios">
                            <span data-feather="home"></span>
                            Sócios
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/admin/panel/leads">
                            <span data-feather="home"></span>
                            Leads
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/admin/panel/donativos">
                            <span data-feather="home"></span>
                            Donativos
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/admin/panel/bilhetes">
                            <span data-feather="home"></span>
                            Bilhetes
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/admin/panel/eventos">
                            <span data-feather="home"></span>
                            eventos
                        </a>
                    </li>
                </ul>

            </div>
        </nav>
        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div class="mt-3">
                <app-admin-panel-socios *ngIf="viewController[0].state"></app-admin-panel-socios>
                <app-admin-panel-leads *ngIf="viewController[1].state"></app-admin-panel-leads>
                <app-admin-panel-donativos *ngIf="viewController[2].state"></app-admin-panel-donativos>
                <app-admin-panel-socios-single *ngIf="viewController[3].state"></app-admin-panel-socios-single>
                <app-admin-panel-eventos *ngIf="viewController[5].state"></app-admin-panel-eventos>
                <app-admin-panel-bilhetes *ngIf="viewController[4].state"></app-admin-panel-bilhetes>
            </div>
            
        </main>
    </div>
</div>