import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  httpOptions = {
    headers: new HttpHeaders({
       'Content-Type': 'application/json; charset=UTF-8'
    })
  };
  
  private eventsURL = environment.apiEndpoint + "/events";
  private ticketsURL = environment.apiEndpoint + "/ticket";
  private quotasURL = environment.apiEndpoint + "/quota";

  constructor(private http: HttpClient, private sessionService : SessionService) { }

  public getEvents() {
    return this.http.get(this.eventsURL, this.httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public checkNIFNIC(nic, nif, eventId) {
    return this.http.get(this.ticketsURL+"/existingNIFNIC?nif="+nif+"&nic="+nic+"&eventuuid="+eventId, this.httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public createTicket(nif, nic, name, email, payMethod, eventuuid, mbwNR, is_mobility, is_guest, is_socio) {
    let data = {
      nif, nic, name, email, payMethod, eventuuid, mbwNR, is_mobility, is_guest, is_socio
    };
    return this.http.post(this.ticketsURL, data, this.httpOptions).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public createTicketSocio(eventuuid, is_mobility) {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    let data = {
      eventuuid,
      is_mobility
    };
    return this.http.post(this.ticketsURL+'/socio', data, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public createSeasonTicketForPayment(payMethod, mbwNR) {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.post(this.ticketsURL+"/season", {payMethod, mbwNR}, httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public hasPendingSeasonTickets() {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.ticketsURL+"/hasTicket", httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }

  public getQuotaInfo() {
    let httpOptionsAuthenticated = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json; charset=UTF-8',
         'x-access-token': this.sessionService.getToken()
      })
    };
    return this.http.get(this.quotasURL+"/quotaSeasonTicket", httpOptionsAuthenticated).pipe(
      //catchError(this.handleError('login()'))
    );
  }
}
