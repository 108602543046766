import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SocioguardService implements CanActivate {

  constructor(private session : SessionService, private router:Router) { }

  canActivate(): boolean {
    let jwtData = this.session.getJWTData();
    if(jwtData.nr_socio){
      return true;
    }else{
   
      this.router.navigate(['/']);
      return false;
    }
  }
}
