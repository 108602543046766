import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { BackOfficeService } from '../services/back-office.service';

@Component({
  selector: 'app-admin-noticias-factory',
  templateUrl: './admin-noticias-factory.component.html',
  styleUrls: ['./admin-noticias-factory.component.css']
})
export class AdminNoticiasFactoryComponent implements OnInit {

  @Input() @Output() isEdit : boolean = false;
  @Output() isEditChange = new EventEmitter<boolean>();

  @Input() @Output() isNew : boolean = false;
  @Output() isNewChange = new EventEmitter<boolean>();

  @Input() @Output() newsObject : any;
  @Output() newsObjectChange = new EventEmitter<any>();

  @Output() handleNewsUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
    private location: LocationStrategy,
    private backOfficeService : BackOfficeService,
    private notifierService : NotifierService
  ) {
    history.pushState(null, null, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
        this.goBack();
    });
  }

  ngOnInit(): void {
  }

  goBack(){
    this.isEditChange.emit(false);
    this.isNewChange.emit(false);
    this.newsObjectChange.emit(null);
  }

  submit(){

    if(this.isEdit){

      this.backOfficeService.updateNews(this.newsObject).subscribe((res:any)=>{
        if(res.status){
          this.notifierService.notify("success", "A notícia foi atualizada");
          this.goBack();
          this.handleNewsUpdate.emit();
        }
      },(error:any)=>{
        this.notifierService.notify("error", "Algo inesperado aconteceu. Se o problema persistir, contacte o administrador de sistema.");
      }
      );

    }else{

      this.backOfficeService.createNews(this.newsObject).subscribe((res:any)=>{
        if(res.uuid){
          this.notifierService.notify("success", "A notícia foi criada");
          this.goBack();
          this.handleNewsUpdate.emit();
        }
      },(error:any)=>{
        this.notifierService.notify("error", "Algo inesperado aconteceu. Se o problema persistir, contacte o administrador de sistema.");
      });

    }

  }

}
