import { Component, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { NotifierService } from 'angular-notifier';
import { BackOfficeService } from '../services/back-office.service';

@Component({
  selector: 'app-admin-destaques',
  templateUrl: './admin-destaques.component.html',
  styleUrls: ['./admin-destaques.component.css']
})
export class AdminDestaquesComponent implements OnInit {

  createButtonDisabled = true;

  highlightObject : {
      id,
      title ,
      description,
      destination_url,
      display_order,
      is_active,
      is_default,
      pending_changes
  };

  highLightWithModificationSet : any;

  constructor(private backOfficeService : BackOfficeService, private notifierService : NotifierService) { 

    this.highLightWithModificationSet = new Set();
  }

  isLoading : boolean = true;
  highLightsLst : [];
  

  ngOnInit() {

    this.highlightObject = {
      id : "",
      title : "",
      description : "",
      destination_url : "",
      display_order : "",
      is_active : "",
      is_default : "",
      pending_changes : false
    };

    this.backOfficeService.getHighlights().subscribe((res:any)=>{
      this.highLightsLst = res;
      this.isLoading = false;
    });

  }

  handleHighlightChange(uuid){
    this.highLightsLst.forEach((element:any) => {
      if(element.uuid == uuid){
        element.pending_changes=true;
      }
    });
    
    
  }

  save(uuid){

    let highlightToSave;
    this.highLightsLst.forEach((ele:any)=>{
      if(ele.uuid == uuid){
        highlightToSave = ele;
      }
    });

    highlightToSave.is_active = highlightToSave.is_active ? "true" : "false";
    highlightToSave.is_default = highlightToSave.is_default ? "true" : "false";


    this.backOfficeService.updateHighlight(highlightToSave).subscribe((res:any)=>{
      if(res.status){
        this.notifierService.notify("success", "O destaque foi atualizado");
        this.highLightsLst.forEach((element:any) => {
          if(element.uuid == uuid){
            element.pending_changes=false;
          }
        });
      }
    },(error:any)=>{
      this.notifierService.notify("error", "Algo inesperado aconteceu. Se o problema persistir, contacte o administrador de sistema.");
    }
    );

    highlightToSave.is_active = highlightToSave.is_active  === "true";
    highlightToSave.is_default = highlightToSave.is_default  === "true";

    
  }

  create(){

    this.highlightObject.is_active = this.highlightObject.is_active ? "true" : "false";
    this.highlightObject.is_default = this.highlightObject.is_default ? "true" : "false";

    this.backOfficeService.createHighlight(this.highlightObject).subscribe((res:any)=>{
      if(res.uuid){
        this.notifierService.notify("success", "O destaque foi criado");
        this.ngOnInit();
        this.handleUpdateNewHighlight();
      }
    },(error:any)=>{
      this.notifierService.notify("error", "Algo inesperado aconteceu. Se o problema persistir, contacte o administrador de sistema.");
    });


  }

  handleUpdateNewHighlight(){
    if(this.highlightObject.title 
      && this.highlightObject.title != "" 
      && this.highlightObject.display_order 
      && this.highlightObject.display_order != ""
      && this.highlightObject.description
      && this.highlightObject.description != ""
      ){
        this.createButtonDisabled = false;
    }else{
      this.createButtonDisabled = true;
    }
  }

}
