import { Component, OnInit } from '@angular/core';
import { TicketsService } from '../services/tickets.service';
import { UtilService } from '../services/util.service';
import { SessionService } from '../services/session.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-bilhetes',
  templateUrl: './bilhetes.component.html',
  styleUrls: ['./bilhetes.component.css']
})
export class BilhetesComponent implements OnInit {

  name : string;
  nif : string;
  nic : string;
  email : string;
  phone : string;
  isValid : boolean = false;

  nifInvalidFeed = "Por favor indique um NIF válido.";
  nicInvalidFeed = "Por favor indique um número de CC válido (XXXXXXXX X XXX).";

  maxsteps : number = 2;

  isLoading = true;
  step : number = 0;
  isFinalStep : boolean = false;

  eventsMap : any = new Map();

  selected = "Futsal";
  showPlaceHolder = true;
  SEASON_TICKET_PRICE = 1;
  
  payMethod : String;
  mbwNR : String;

  ref: string;
  entity: string;
  value: number;
  maxDate: Date;

  guest : boolean;
  mobility : boolean;

  nomeIsValid : boolean = false;
  emailIsValid : boolean = false;
  nicIsValid : boolean = false;
  nifIsValid : boolean = false;

  isSocio : boolean = false;
  isPartner : boolean = false;

  errorMessage : string;
  socioData : any;

  selectedEvent : any ;/*= {
    "id": 2,
    "uuid": "8beede8d-b400-404e-8abc-b5ea713c8b17",
    "type": 1,
    "title": "Campeonato Distrital | AFVR",
    "sub_title": null,
    "start_date": "2023-09-30 21:00:00",
    "end_date": null,
    "team_a": 1,
    "team_b": 2,
    "price": 3,
    "venue": 1,
    "createdAt": "2023-09-20 23:44:45",
    "updatedAt": "2023-09-20 23:44:45",
    "type_event_type": {
        "type": "Futsal"
    },
    "team_b_team": {
        "name": "Juventude Pedras Salgadas",
        "logo": "https://media.ascvd.pt/futsal/logos/afvr_juvp-salgadas.png"
    },
    "team_a_team": {
        "name": "Associação Vale d'Ouro",
        "logo": "https://media.ascvd.pt/futsal/logos/afvr_ascvaledouro.png"
    },
    "venue_venue": {
        "name": "Pav Municipal Alijó",
        "capacity": 250
    }
};
*/


  constructor(private ticketsService : TicketsService, private utilService : UtilService,
     private sessionService : SessionService, private notifierService : NotifierService) { }

  ngOnInit(): void {

    if(this.sessionService.isAuthenticatedSocio()){
      this.isSocio = true;
      let socioData = this.sessionService.getJWTData();
      if(socioData.tipo_socio == 5 || socioData.tipo_socio == 6){
        this.isPartner = true;
      }
    }

    this.ticketsService.getEvents().subscribe((result:any)=>{

      let eventsMap = new Map();

      result.forEach((event)=>{
        if(!eventsMap.has(event.type_event_type.type)){
          eventsMap.set(event.type_event_type.type, [event]);
        }else{
          eventsMap.get(event.type_event_type.type).push(event);
        } 
      });

      this.eventsMap = eventsMap;
      if(this.eventsMap.size == 0){
        this.showPlaceHolder = true;
      }else{
        this.showPlaceHolder = false;
      }

      this.isLoading = false;
    });
  }

  setActive(event){
    this.selected = event.target.text;
  }


  buyTicket(uuid){
    
    this.selectedEvent = this.eventsMap.get(this.selected).find((event)=>event.uuid == uuid);
    if(this.isSocio){
      this.isLoading = true;                                     //MOBILIDADE
      this.ticketsService.createTicketSocio(this.selectedEvent.uuid, false).subscribe((result : any)=>{
        if(result.status){

          this.step = -1;
          this.isFinalStep = true;
          this.isLoading = false;
          
        }else{
          this.step = 0;
          this.errorMessage = result.message;
          this.isLoading = false;
          this.notifierService.notify("error", this.errorMessage);
        }
      });

    }else{
      
      //console.log(this.selectedEvent);
      this.step = 1;
    }

  }


  stepBack(){
    this.step -= 1;
  }

  pay(){

    this.isLoading = true;
    this.ticketsService.createTicket(this.nif, this.nic,
       this.name, this.email, this.payMethod,
        this.selectedEvent.uuid, this.mbwNR, this.mobility, this.guest, false).subscribe((result : any)=>{
          if(result.status){

            this.ref = result.ref;
            this.value = result.value;
            this.entity = result.entity;
            this.maxDate = result.maxDate;

            this.step = -1;
            this.isFinalStep = true;
            this.isLoading = false;
          }else{
            this.step = 1;
            this.errorMessage = result.message;
            this.isLoading = false;
            this.notifierService.notify("error", this.errorMessage);
          }
    });

  }

  selectPayMethod(event, paymethod) {
    this.payMethod = paymethod;

    let elements = document.getElementsByClassName("activePayMethod");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("activePayMethod");
    }

    let element = event.target;
    while (element.nodeName != "LI") {
      element = element.parentElement;
    }

    element.classList.add("activePayMethod");

    this.validatePaymentOptions();
  }

  isValidPayOptions: boolean = false;

  validatePaymentOptions() {
    if (this.step == 2) {
      if (this.payMethod != "MB" && this.payMethod != "MBW") {
        this.isValidPayOptions = false;
        return false;
      }

      if (this.payMethod == "MBW") {
        let availablePrefix = ["91", "92", "93", "96"];
        if (
          !this.mbwNR ||
          this.mbwNR.length != 9 ||
          !availablePrefix.includes(this.mbwNR[0] + this.mbwNR[1])
        ) {
          this.isValidPayOptions = false;
          //toast erro
          return false;
        }
      }
    }
    this.isValidPayOptions = true;
  }

  async validateNIC() {

    if (!this.nic || !this.utilService.validateCC(this.nic.replace(/\s/g, ""))) {
      this.nicInvalidFeed = "Por favor indique um número de CC válido (XXXXXXXX X XXX).";
      this.setValidStatus("nic", false);
      this.nicIsValid = false;
      return false;

    } else{

      this.setValidStatus("nic", true);
      const response:any = await this.ticketsService.checkNIFNIC(this.nic, this.nif, this.selectedEvent.uuid).toPromise();

      if(response.nicHasTicket){
        this.nicInvalidFeed="Já existe um bilhete registado para este evento com o NIC fornecido.";
        this.setValidStatus("nic", false);
        this.nicIsValid = false;
        return false;
      }else if(response.nicHasSeasonTicket){
        this.nicInvalidFeed="Já existe um bilhete de época registado com o NIC fornecido.";
        this.setValidStatus("nic", false);
        this.nicIsValid = false;
        return false;
      }
      this.nicIsValid = true;
      return true;
    }
 
  }
  
  validateEmail() {
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!this.email || !this.email.match(emailRegex)) {
      this.setValidStatus("email", false);
      this.emailIsValid = false;
      return false;
    } else {
      this.emailIsValid = true;
      this.setValidStatus("email", true);
      return true;
    }
  }
  
  validateName() {
    if ( !this.name || this.name.length < 3) {
      this.setValidStatus("name", false);
      this.nomeIsValid = false;
      return false;
    } else {
      this.nomeIsValid = true;
      this.setValidStatus("name", true);
      return true;
    }
  }
  
  async validateFormNIF(){
 
    if (!this.nif || !this.utilService.validateNIF(this.nif)) {
      this.nifInvalidFeed = "Por favor indique um NIF válido.";
      this.setValidStatus("nif", false);
      this.nifIsValid = false;
      return false;
    } else {
      this.setValidStatus("nif", true);
      const response:any = await this.ticketsService.checkNIFNIC(this.nic, this.nif, this.selectedEvent.uuid).toPromise();

      if(response.nifHasTicket){
        this.nifInvalidFeed="Já existe um bilhete registado para este evento com o NIF fornecido.";
        this.setValidStatus("nif", false);
        this.nifIsValid = false;
        return false;
      }else if(response.nifHasSeasonTicket){
        this.nifInvalidFeed="Já existe um bilhete de época registado com o NIF fornecido.";
        this.setValidStatus("nif", false);
        this.nifIsValid = false;
        return false;
      }
      this.nifIsValid = true;
      return true;
    }

  }

  setValidStatus(eName, isValid) {
    let element = document.getElementById(eName);
    if (isValid) {
      element.classList.remove("is-invalid");
      element.classList.add("is-valid");
    } else {
      element.classList.remove("is-valid");
      element.classList.add("is-invalid");
    }
  }

  async validateFormData() {
    if (
      this.validateName() &&
      await this.validateFormNIF() &&
      await this.validateNIC() &&
      this.validateEmail()
    ) {
      this.isValid = true;
      this.step += 1;
      return true;
    } else {
      this.isValid = false;
      return false;
    }
  }

}
