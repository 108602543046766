<app-loader [isOpen]="isLoading"></app-loader>
<!-- hero slider -->
<section class="hero-section overlay bg-cover" data-background="../../assets/img/bg.jpg" style="background-image: url('../../assets/img/bg.jpg');">
    <div class="container">
      <div class="hero-slider">
        <!-- slider item -->
        <div #highlightsDOM class="hero-slider-item" *ngFor="let highlight of highlightLst" >
          <div class="row">
            <div class="col-md-8">
              <h1 class="text-white" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".1">{{highlight.title}}</h1>
              <p class="text-muted mb-4" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".4">{{highlight.description}}</p>
              <a *ngIf="highlight.destination_url" target="_blank" href="{{highlight.destination_url}}" class="btn btn-primary" data-animation-out="fadeOutRight" data-delay-out="5" data-duration-in=".3" data-animation-in="fadeInLeft" data-delay-in=".7">Ver mais</a>
            </div>
          </div>
        </div>
                
      </div>
    </div>
  </section>
  <!-- /hero slider -->
  
  