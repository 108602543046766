import { Component, OnInit } from '@angular/core';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-admin-panel-socios',
  templateUrl: './admin-panel-socios.component.html',
  styleUrls: ['./admin-panel-socios.component.css']
})
export class AdminPanelSociosComponent implements OnInit {

  socios : any = [];

  constructor(private adminService : AdminServiceService) { }

  ngOnInit(): void {
    this.adminService.getSocios().subscribe((result)=>{
      this.socios = result;
    });
  }

}
